import { TAuthInitialState } from "../types/auth";

import { AuthAction } from "../actions/authAction";

export const initialState = {
  isAuthenticated: false,
  isError: false,
  isSuccess: false,
  isEmailExist: false,
  isUpdatePrices: false,
  isUpdateAccount: false,
  forgotStatus: 0,
  id: null,
  status: 0,
  name: "",
  address1: "",
  address2: "",
  zipCode: "",
  city: "",
  phone: "",
  email: "",
  siret: "",
  T1: 0,
  T2: 0,
  T3: 0,
  M1: 0,
  M2: 0,
  M3: 0,
  P1: 0,
  P2: 0,
  ingOpaque: 0,
  ingMetal: 0,
  ingNacre: 0,
  discountM: 0,
  discountP: 0,
  discountPart: 0,
  discountI: 0,
  discountInvoice: 0,
  services: [],
  indexUpdatePicture: 0,
  days: [],
  times: [],
  slot: 0,
} satisfies TAuthInitialState;

export const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case AuthAction.REFRESH_TOKEN:
      return {
        ...state,
        isAuthenticated: action.success,
        id: action.data ? action.data.garageId : null,
        status: action.data ? action.data.status : null,
        name: action.data ? action.data.name : null,
        address1: action.data ? action.data.address1 : null,
        address2: action.data ? action.data.address2 : null,
        zipCode: action.data ? action.data.zipCode : null,
        city: action.data ? action.data.city : null,
        phone: action.data ? action.data.phone : null,
        email: action.data ? action.data.email : null,
        siret: action.data ? action.data.siret : null,
        T1: action.data ? action.data.T1 : null,
        T2: action.data ? action.data.T2 : null,
        T3: action.data ? action.data.T3 : null,
        M1: action.data ? action.data.M1 : null,
        M2: action.data ? action.data.M2 : null,
        M3: action.data ? action.data.M3 : null,
        P1: action.data ? action.data.P1 : null,
        P2: action.data ? action.data.P2 : null,
        ingOpaque: action.data ? action.data.ingOpaque : null,
        ingMetal: action.data ? action.data.ingMetal : null,
        ingNacre: action.data ? action.data.ingNacre : null,
        discountM: action.data ? action.data.discountM : null,
        discountP: action.data ? action.data.discountP : null,
        discountPart: action.data ? action.data.discountPart : null,
        discountI: action.data ? action.data.discountI : null,
        discountInvoice: action.data ? action.data.discountInvoice : null,
        services: action.data ? action.data.services : [],
        days: action.data ? action.data.days : [],
        times: action.data ? action.data.times : [],
        slot: action.data ? action.data.slot : 0,
      };
    case AuthAction.SIGNIN:
      return {
        ...state,
        isError: !action.success,
        isAuthenticated: action.success,
        id: action.data ? action.data.garageId : null,
        status: action.data ? action.data.status : null,
        name: action.data ? action.data.name : null,
        address1: action.data ? action.data.address1 : null,
        address2: action.data ? action.data.address2 : null,
        zipCode: action.data ? action.data.zipCode : null,
        city: action.data ? action.data.city : null,
        phone: action.data ? action.data.phone : null,
        email: action.data ? action.data.email : null,
        siret: action.data ? action.data.siret : null,
        T1: action.data ? action.data.T1 : null,
        T2: action.data ? action.data.T2 : null,
        T3: action.data ? action.data.T3 : null,
        M1: action.data ? action.data.M1 : null,
        M2: action.data ? action.data.M2 : null,
        M3: action.data ? action.data.M3 : null,
        P1: action.data ? action.data.P1 : null,
        P2: action.data ? action.data.P2 : null,
        ingOpaque: action.data ? action.data.ingOpaque : null,
        ingMetal: action.data ? action.data.ingMetal : null,
        ingNacre: action.data ? action.data.ingNacre : null,
        discountM: action.data ? action.data.discountM : null,
        discountP: action.data ? action.data.discountP : null,
        discountPart: action.data ? action.data.discountPart : null,
        discountI: action.data ? action.data.discountI : null,
        discountInvoice: action.data ? action.data.discountInvoice : null,
        services: action.data ? action.data.services : [],
        days: action.data ? action.data.days : [],
        times: action.data ? action.data.times : [],
        slot: action.data ? action.data.slot : 0,
      };
    case AuthAction.SIGNUP:
      return {
        ...state,
        isError: !action.success,
        isSuccess: action.success,
        isEmailExist: action.isExist,
      };
    case AuthAction.SIGNOUT:
      return {
        ...state,
        isAuthenticated: false,
      };
    case AuthAction.FORGOT:
      return {
        ...state,
        isError: !action.success,
        isSuccess: action.success,
      };
    case AuthAction.FORGOT_CHECK:
      return {
        ...state,
        forgotStatus: action.success ? 1 : 2,
      };
    case AuthAction.FORGOT_UPDATE:
      return {
        ...state,
        isError: !action.success,
        forgotStatus: action.success ? 3 : 1,
      };
    case AuthAction.RESET_ERROR:
      return {
        ...state,
        isError: false,
        isEmailExist: false,
      };
    case AuthAction.UPDATE_DATA:
      return {
        ...state,
        ...([
          "T1",
          "T2",
          "T3",
          "M1",
          "M2",
          "M3",
          "P1",
          "P2",
          "ingOpaque",
          "ingMetal",
          "ingNacre",
          "discountM",
          "discountP",
          "discountPart",
          "discountI",
          "discountInvoice",
        ].includes(action.body.name)
          ? { isUpdatePrices: true }
          : {}),
        ...([
          "name",
          "address1",
          "address2",
          "zipCode",
          "city",
          "phone",
          "email",
          "siret",
        ].includes(action.body.name)
          ? { isUpdateAccount: true }
          : {}),
        [action.body.name]: action.body.value,
      };
    case AuthAction.UPDATE_ACCOUNT:
      return {
        ...state,
        isUpdateAccount: false,
      };
    case AuthAction.UPDATE_ACCOUNT_PICTURE:
      return {
        ...state,
        indexUpdatePicture: state.indexUpdatePicture + 1,
      };
    case AuthAction.UPDATE_PRICES:
      return {
        ...state,
        isUpdatePrices: false,
      };
    case AuthAction.UPDATE_SERVICES:
      return {
        ...state,
        services: action.data || state.services,
      };
    case AuthAction.UPDATE_DAYS:
      return {
        ...state,
        days: action.data || action.body.days,
      };
    case AuthAction.UPDATE_TIMES:
      return {
        ...state,
        times: action.data || action.body.times,
      };
    case AuthAction.UPDATE_SLOT:
      return {
        ...state,
        slot: action.data || action.body.slot,
      };
    case AuthAction.UPDATE_AVAILABILITIES:
      return {
        ...state,
      };
    default:
      return state;
  }
};
