import check from "../../images/check-large.svg";

function ClientPicturesSuccess() {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        backgroundColor: "var(--primary-color)",

        flexDirection: "column",
        padding: 20,
        justifyContent: "center",
        textAlign: "center",
        gap: 14,
      }}
    >
      <img alt="check" src={check} height={94} />
      <h2 style={{ color: "#fff", margin: 0 }}>
        Votre dossier a bien été transmis à notre équipe.
      </h2>
      <p style={{ color: "#fff" }}>
        Nous reviendrons vers vous très rapidement afin de poursuivre la gestion
        de votre dossier.
      </p>
      <p style={{ color: "#fff" }}>
        Un récapitulatif au format PDF vous a été envoyé par mail.
      </p>
    </div>
  );
}

export default ClientPicturesSuccess;
