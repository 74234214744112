import { Row } from "react-flexbox-grid";

import Tenders from "../../components/garage/Tenders";
import TableAppointments from "../../components/garage/TableAppointments";
import Appointments from "../../components/garage/Appointments";
import Prices from "../../components/garage/Prices";
import Services from "../../components/garage/Services";

function GarageDashboard() {
  return (
    <Row style={{ display: "flex", rowGap: 20 }}>
      <Tenders />
      <Appointments />
      <TableAppointments />
      <Prices />
      <Services />
    </Row>
  );
}

export default GarageDashboard;
