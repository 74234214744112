import { Icon } from "@sahadevia/ui-kit-modern-sahadevia";
import moment from "moment";

import { EmptyAppointment } from "../../types/garage";

interface ModalTableAppointmentsProps {
  appointment: EmptyAppointment;
  onClose: Function;
}

export default function ModalAppointment({
  appointment,
  onClose,
}: ModalTableAppointmentsProps) {
  return (
    <>
      <div
        style={{
          position: "fixed",
          display: "flex",
          left: 0,
          bottom: 0,
          right: 0,
          top: 0,
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 100,
        }}
      >
        <div
          style={{
            gap: 10,
            display: "flex",
            flexDirection: "column",
            width: 600,
            maxWidth: "100%",
            maxHeight: "100%",
            backgroundColor: "#fff",
            borderRadius: 10,
            padding: 20,
          }}
        >
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <h3>Détail RDV</h3>
            <Icon icon={"Cross"} size={20} onClick={() => onClose()} />
          </div>
          <span>
            Nom :{" "}
            <b>
              {appointment.kind} {appointment.name}
            </b>
          </span>
          <span>
            N° sinistre : <b>{appointment.sinisterNumber}</b>
          </span>
          <span>
            Immatriculation : <b>{appointment.licensePlate}</b>
          </span>
          <span>
            N° téléphone : <b>{appointment.phone}</b>
          </span>
          <span>
            Marque : <b>{appointment.brand}</b>
          </span>
          <span>
            Modèle : <b>{appointment.model}</b>
          </span>
          <span>
            Date / Heure RDV :{" "}
            <b>{moment(appointment.date).format("DD/MM/YYYY HH:mm")}</b>
          </span>
        </div>
      </div>
    </>
  );
}
