import { ChangeEvent, useRef, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { useSelector } from "react-redux";
import { Button } from "@sahadevia/ui-kit-modern-sahadevia";

import Card from "../../components/garage/Card";
import Input from "../../components/garage/Input";

import { TbaseReducer } from "../../types/reducerType";

import { useAppDispatch } from "../../reducers";

import {
  AuthAction,
  updateAccount,
  updateAccountPicture,
  updatePassword,
} from "../../actions/authAction";
import { ResultBase64, getBase64 } from "../../utils/file";

import { ROUTE_API } from "../../constants";

function GarageAccount() {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const dispatch = useAppDispatch();

  const {
    id,
    name,
    address1,
    address2,
    zipCode,
    city,
    phone,
    email,
    siret,
    isUpdateAccount,
    indexUpdatePicture,
  } = useSelector((state: TbaseReducer) => state.authReducer);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const _updateAccount = () => {
    dispatch(
      updateAccount({
        name,
        address1,
        address2,
        zipCode,
        city,
        phone,
        siret,
      })
    );
  };

  return (
    <>
      <Row style={{ display: "flex", rowGap: 20 }}>
        <Col xs={12}>
          <Card title="Informations générales" noHeight>
            <Row>
              <Col xs={12} md={4} lg={3}>
                <div
                  className="d-flex"
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <div
                    onClick={() => inputRef.current?.click()}
                    className="cursor-pointer"
                    style={{
                      position: "relative",
                      borderRadius: "50%",
                      height: 200,
                      width: 200,
                      overflow: "hidden",
                      border: "solid 2px #eeeeee",
                    }}
                  >
                    <img
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                      src={`${ROUTE_API}/garage/auth/get-account-picture/${id}?update=${indexUpdatePicture}`}
                      alt="profile"
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={8} lg={9}>
                <div
                  className="d-flex"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Row className="d-flex" style={{ rowGap: 16 }}>
                    <Col xs={12} md={6} lg={4}>
                      <Input
                        value={name}
                        onChange={(e) =>
                          dispatch({
                            type: AuthAction.UPDATE_DATA,
                            body: {
                              name: "name",
                              value: e.target.value,
                            },
                          })
                        }
                        type="text"
                        placeholder="Nom"
                        isFull
                      />
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                      <Input
                        value={address1}
                        onChange={(e) =>
                          dispatch({
                            type: AuthAction.UPDATE_DATA,
                            body: {
                              name: "address1",
                              value: e.target.value,
                            },
                          })
                        }
                        type="text"
                        placeholder="Adresse 1"
                        isFull
                      />
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                      <Input
                        value={address2}
                        onChange={(e) =>
                          dispatch({
                            type: AuthAction.UPDATE_DATA,
                            body: {
                              name: "address2",
                              value: e.target.value,
                            },
                          })
                        }
                        type="text"
                        placeholder="Adresse 2"
                        isFull
                      />
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                      <Input
                        value={zipCode}
                        onChange={(e) =>
                          dispatch({
                            type: AuthAction.UPDATE_DATA,
                            body: {
                              name: "zipCode",
                              value: e.target.value,
                            },
                          })
                        }
                        type="text"
                        placeholder="Code postal"
                        isFull
                      />
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                      <Input
                        value={city}
                        onChange={(e) =>
                          dispatch({
                            type: AuthAction.UPDATE_DATA,
                            body: {
                              name: "city",
                              value: e.target.value,
                            },
                          })
                        }
                        type="text"
                        placeholder="Ville"
                        isFull
                      />
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                      <Input
                        value={phone}
                        onChange={(e) =>
                          dispatch({
                            type: AuthAction.UPDATE_DATA,
                            body: {
                              name: "phone",
                              value: e.target.value,
                            },
                          })
                        }
                        type="phone"
                        placeholder="Téléphone"
                        isFull
                      />
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                      <Input
                        disabled
                        value={email}
                        onChange={() => {}}
                        type="email"
                        placeholder="Adresse E-mail"
                        isFull
                      />
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                      <Input
                        value={siret}
                        onChange={(e) =>
                          dispatch({
                            type: AuthAction.UPDATE_DATA,
                            body: {
                              name: "siret",
                              value: e.target.value,
                            },
                          })
                        }
                        type="text"
                        placeholder="SIRET"
                        isFull
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={12}>
                <div className="d-flex" style={{ justifyContent: "end" }}>
                  <Button
                    disabled={!isUpdateAccount}
                    text="Enregistrer"
                    onClick={() => _updateAccount()}
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12}>
          <Card title="Sécurité" noHeight>
            <Row className="d-flex" style={{ rowGap: 16 }}>
              <Col xs={12} md={6} lg={4}>
                <Input
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  type="password"
                  placeholder="Ancien mot de pase"
                  isFull
                />
              </Col>
              <Col xs={12} md={6} lg={4}>
                <Input
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  type="password"
                  placeholder="Nouveau mot de passe"
                  isFull
                />
              </Col>
              <Col xs={12} md={6} lg={4}>
                <Input
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                  placeholder="Répeter le mot de passe"
                  isFull
                />
              </Col>
              <Col xs={12}>
                <div className="d-flex" style={{ justifyContent: "end" }}>
                  <Button
                    disabled={
                      !oldPassword ||
                      !newPassword ||
                      !confirmPassword ||
                      newPassword !== confirmPassword
                    }
                    text="Modifier le mot de passe"
                    onClick={() =>
                      dispatch(updatePassword(oldPassword, newPassword))
                    }
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.files) {
            let file = e.target.files[0];

            getBase64(file)
              .then((result: ResultBase64) => {
                dispatch(updateAccountPicture(result.base64, result.type));
              })
              .catch(() => {});
          }
        }}
        style={{ display: "none" }}
      />
    </>
  );
}

export default GarageAccount;
