const getCurrencyValue = (value: null | number, digits = 2) => {
  if (value == null) {
    return "";
  }

  const optionsCurrency = {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  };
  return new Intl.NumberFormat("fr-FR", optionsCurrency).format(value);
};

const getPercentageValue = (value: null | number, digits = 2) => {
  if (value == null) {
    return "";
  }

  const optionsPercentage = {
    style: "percent",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  };
  return new Intl.NumberFormat("fr-FR", optionsPercentage).format(value);
};

export { getCurrencyValue, getPercentageValue };
