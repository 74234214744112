import { useCallback, useEffect } from "react";
import { Col, Row } from "react-flexbox-grid";
import moment from "moment";
import { useSelector } from "react-redux";

import Card from "../../components/garage/Card";

import { getAppointmentsHistory } from "../../actions/garageAction";

import { useAppDispatch } from "../../reducers";
import { TbaseReducer } from "../../types/reducerType";
import { AppointmentHistory } from "../../types/garage";

function GarageAppointments() {
  const dispatch = useAppDispatch();

  const { id } = useSelector((state: TbaseReducer) => state.authReducer);
  const { appointmentsHistory } = useSelector(
    (state: TbaseReducer) => state.garageReducer
  );

  const _getAppointmentsHistory = useCallback(() => {
    if (id) {
      dispatch(getAppointmentsHistory(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    _getAppointmentsHistory();
  }, [id, _getAppointmentsHistory]);

  return (
    <>
      <Row style={{ display: "flex", rowGap: 20 }}>
        <Col xs={12}>
          <Card title="Historique des RDV" noBodyPadding noHeight>
            {appointmentsHistory.length ? (
              <table className="table-tender">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date RDV</th>
                    <th>Réf</th>
                    <th>Marque</th>
                    <th>Modèle</th>
                    <th>Immatriculation</th>
                  </tr>
                </thead>
                <tbody>
                  {appointmentsHistory.map(
                    (element: AppointmentHistory, idx: number) => (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>
                          {moment(element.date).format("DD/MM/YYYY HH:mm:ss")}
                        </td>
                        <td>{element.sinisterNumber}</td>
                        <td>{element.model}</td>
                        <td>{element.model}</td>
                        <td>{element.licensePlate}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  minHeight: 200,
                }}
              >
                <span>Aucun RDV</span>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default GarageAppointments;
