import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import caretLeftWhite from "../../images/caret-left-white.svg";
import caretRightWhite from "../../images/caret-right-white.svg";

import Header from "../../components/UI/header/Header";
import Button from "../../components/UI/Button";
import Loader from "../../components/UI/Loader";

import ProjectE from "../../components/client/ProjectE";
import Star from "../../components/client/Star";

import { getCurrencyValue } from "../../utils/general";

import { useAppDispatch } from "../../reducers";

import {
  ClientAction,
  getGarageAppointments,
  validateAppointment,
} from "../../actions/clientAction";

import { TbaseReducer } from "../../types/reducerType";
import { Garage, ClientGarageProps } from "../../types/garage";

import { ROUTE_API } from "../../constants";

const months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

function ClientGarage({ isBack = true }: ClientGarageProps) {
  let {
    garages,
    garageId,
    data,
    dates,
    licensePlate,
    sinisterNumber,
    appointmentStatus,
    isLoadAppointment,
  } = useSelector((state: TbaseReducer) => state.clientReducer);

  const { token } = useParams();

  const dispatch = useAppDispatch();
  const location = useLocation();

  const [activeDate, setActiveDate] = useState("");
  const [activeTime, setActiveTime] = useState("");
  const [isCalendar, setIsCalendar] = useState(false);
  const [days, setDays] = useState<Array<Array<null | number>>>([]);
  const [year, setYear] = useState(parseInt(moment().format("YYYY")));
  const [month, setMonth] = useState(parseInt(moment().format("MM")));

  const handlePreviousMonthClick = () => {
    setMonth(month - 1);
  };

  const handleNextMonthClick = () => {
    setMonth(month + 1);
  };

  const getArrayDays = useCallback(() => {
    const endOfMonth = parseInt(
      moment(`${year}-${month}`, "YYYY-M").endOf("month").format("D")
    );

    const startDayOfMonth = moment(`${year}-${month}`, "YYYY-M").isoWeekday();

    let rows: Array<Array<null | number>> = [];

    for (
      let index = startDayOfMonth - 1;
      index < endOfMonth + (startDayOfMonth - 1);
      index++
    ) {
      const col = index % 7;
      const row = parseInt((index / 7).toString());

      if (!rows.hasOwnProperty(row)) {
        rows.push([null, null, null, null, null, null, null]);
      }
      rows[row][col] = index - startDayOfMonth + 2;
    }

    setDays(rows);
  }, [month, year]);

  const handleMonthChange = useCallback(() => {
    if (month > 12) {
      setYear(year + 1);
      setMonth(1);
    } else if (month < 1) {
      setYear(year - 1);
      setMonth(12);
    } else {
      getArrayDays();
    }
  }, [month, year, getArrayDays]);

  useEffect(() => {
    handleMonthChange();
  }, [month, handleMonthChange]);

  useEffect(() => {
    getArrayDays();
  }, [getArrayDays]);

  useEffect(() => {
    if (garageId) {
      dispatch(getGarageAppointments(garageId));
    }
  }, [garageId, dispatch]);

  const garage = garages.find((garage: Garage) => garage.id === garageId);

  if (appointmentStatus === 1) {
    if (location.pathname.includes("/client/garages-ao")) {
      return (
        <div
          style={{
            position: "absolute",
            display: "fixed",
            flexDirection: "column",
            gap: 8,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            padding: 10,
            zIndex: 500,
            backgroundColor: "var(--primary-color)",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <h3
              style={{
                color: "#fff",
                margin: 0,
              }}
            >
              Demande de RDV effectuée
            </h3>
            <p style={{ color: "#fff" }}>
              Le {moment(activeTime).format("DD/MM/YYYY")} à{" "}
              {moment(activeTime).format("HH:mm")}
            </p>
            <p
              style={{
                color: "#fff",
              }}
            >
              Votre demande a été confirmée. merci de vous présenter chez votre
              réparateur avec votre véhicule à la date indiquée ci-dessus
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            position: "absolute",
            display: "fixed",
            flexDirection: "column",
            gap: 8,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            padding: 10,
            zIndex: 500,
            backgroundColor: "var(--primary-color)",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <h3
              style={{
                color: "#fff",
                margin: 0,
              }}
            >
              Demande de RDV effectuée
            </h3>
            <p style={{ color: "#fff" }}>
              Le {moment(activeTime).format("DD/MM/YYYY")} à{" "}
              {moment(activeTime).format("HH:mm")}
            </p>
            <p
              style={{
                color: "#fff",
              }}
            >
              Votre demande est en attente de confirmation par le réparateur,
              nous reviendrons vers vous rapidement pour confirmer votre RDV,
              merci.
            </p>
          </div>
        </div>
      );
    }
  } else if (appointmentStatus === 2) {
    return (
      <div
        style={{
          position: "absolute",
          display: "fixed",
          flexDirection: "column",
          gap: 8,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          padding: 10,
          zIndex: 500,
          backgroundColor: "var(--primary-color)",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <h3
            style={{
              color: "#fff",
              margin: 0,
            }}
          >
            Échec de la demande de RDV
          </h3>
          <p
            style={{
              color: "#fff",
            }}
          >
            Nous avons rencontré une erreur lors de votre demande de rdv,
            veuillez réessayer ou nous contacter afin d’obtenir de l’aide.
          </p>
          <div style={{ margin: "auto" }}>
            <Button
              title="Réssayer"
              white
              onClick={() => window.location.reload()}
            />
          </div>
        </div>
      </div>
    );
  }

  if (!garage) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        gap: 8,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        padding: 10,
        zIndex: 500,
        backgroundColor: "#fff",
      }}
    >
      <Header
        title={"Recherche de réparateur"}
        currentPage={20}
        totalPage={0}
        onInfoClick={
          isBack
            ? () =>
                dispatch({
                  type: ClientAction.SET_GARAGE_ID,
                  body: { garageId: 0 },
                })
            : undefined
        }
      />

      <div
        style={{
          width: "100%",
          height: 260,
          borderRadius: 10,
          position: "relative",
          flexShrink: 0,
          overflow: "hidden",
        }}
      >
        <img
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          alt="presentation"
          src={`${ROUTE_API}/garage/auth/get-account-picture/${garage.id}`}
        />
      </div>
      <div
        style={{
          display: "flex",
          gap: 10,
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div style={{ display: "flex", gap: 10 }}>
          <div className="d-flex" style={{ alignItems: "center" }}>
            <h3 style={{ margin: 0 }}>{garage.name}</h3>
            <Star value={garage.rank} />
          </div>
          <ProjectE value={garage.projectE} />
          <h3
            style={{
              margin: 0,
              marginLeft: "auto",
              color: "var(--primary-color)",
              fontWeight: "bold",
            }}
          >
            {getCurrencyValue(garage.price)}
          </h3>
        </div>
        <div>
          <p style={{ margin: 0 }}>- Facilité de règlement</p>
          <p style={{ margin: 0 }}>- Lavage int/ext</p>
          <p style={{ margin: 0 }}>- Service à domicile</p>
          <p style={{ margin: 0 }}>- Véhicule de prêt</p>
          <p style={{ margin: 0 }}>- Dépannage / remorquage</p>
          <p style={{ margin: 0 }}>- Réparation / peinture carrosserie</p>
          <p style={{ margin: 0 }}>- Réparation / entretien mécanique </p>
          <p style={{ margin: 0 }}>- Réparation / diagnostic électronique</p>
        </div>
        <p style={{ color: "var(--primary-color)" }}>
          {garage.address1}
          {garage.address2 ? ` ${garage.address2}` : ""}, {garage.zipCode}{" "}
          {garage.city}
        </p>
        {garage.distance ? (
          <p style={{ color: "var(--primary-color)" }}>
            À {garage.distance} km de votre position.
          </p>
        ) : null}
        {moment(garage.nextAppointment).isSameOrAfter(moment()) ? (
          <>
            <p
              style={{
                color: "var(--primary-color)",
                marginTop: "auto",
                textAlign: "center",
              }}
            >
              Prochain RDV :{" "}
              {moment(garage.nextAppointment).format("DD/MM/YYYY")}
            </p>
            <div style={{ marginLeft: "auto", marginRight: "auto" }}>
              <Button title="Réserver" onClick={() => setIsCalendar(true)} />
            </div>
          </>
        ) : (
          <>
            <p
              style={{
                color: "var(--red)",
                marginTop: "auto",
                textAlign: "center",
              }}
            >
              Aucun RDV disponible
            </p>
          </>
        )}
      </div>
      {isCalendar ? (
        <div
          style={{
            position: "fixed",
            display: "flex",
            flexDirection: "column",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#3597D3",
            color: "#fff",
            padding: 10,
            gap: 10,
            borderTopLeftRadius: 60,
            borderTopRightRadius: 60,
            fontSize: 14,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 10,
            }}
          >
            <span
              className="cursor-pointer"
              onClick={() => handlePreviousMonthClick()}
            >
              <img
                height={24}
                style={{ marginTop: 5 }}
                src={caretLeftWhite}
                alt="caretLeftWhite"
              />
            </span>
            <span
              style={{
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              {months[month - 1]} - {year}
            </span>
            <span
              className="cursor-pointer"
              onClick={() => handleNextMonthClick()}
            >
              <img
                height={24}
                style={{ marginTop: 5 }}
                src={caretRightWhite}
                alt="caretRightWhite"
              />
            </span>
          </div>
          <div>
            <table className="calendar-table">
              <thead>
                <tr>
                  <th>Lun.</th>
                  <th>Mar.</th>
                  <th>Mer.</th>
                  <th>Jeu.</th>
                  <th>Ven.</th>
                  <th>Sam.</th>
                </tr>
              </thead>
              <tbody>
                {days.map((row: Array<null | number>, idxRow: number) => (
                  <tr key={idxRow}>
                    {row.map((col: null | number, idxCol: number) =>
                      idxCol < 6 ? (
                        col ? (
                          <td key={`${idxRow}-${idxCol}`}>
                            <div
                              className={`${
                                activeDate ===
                                moment(
                                  `${year}-${month}-${col}`,
                                  "YYYY-M-D"
                                ).format("YYYY-MM-DD")
                                  ? "active"
                                  : ""
                              } ${
                                dates.includes(
                                  moment(
                                    `${year}-${month}-${col}`,
                                    "YYYY-M-D"
                                  ).format("YYYY-MM-DD")
                                )
                                  ? ""
                                  : "disabled"
                              } cursor-pointer `}
                              onClick={() => {
                                if (
                                  dates.includes(
                                    moment(
                                      `${year}-${month}-${col}`,
                                      "YYYY-M-D"
                                    ).format("YYYY-MM-DD")
                                  )
                                ) {
                                  setActiveDate(
                                    moment(
                                      `${year}-${month}-${col}`,
                                      "YYYY-M-D"
                                    ).format("YYYY-MM-DD")
                                  );
                                }
                              }}
                            >
                              <span>
                                {moment(
                                  `${year}-${month}-${col}`,
                                  "YYYY-M-D"
                                ).format("DD")}
                              </span>
                            </div>
                          </td>
                        ) : (
                          <td></td>
                        )
                      ) : null
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              borderRadius: 6,
              minHeight: 100,
              gap: 6,
              padding: 8,
            }}
          >
            {data
              .filter(
                (element: string) =>
                  activeDate === moment(element).format("YYYY-MM-DD")
              )
              .map((date: string, idx: number) => (
                <p
                  className={`cursor-pointer select-time ${
                    date === activeTime ? "active" : ""
                  }`}
                  key={idx}
                  onClick={() => setActiveTime(date)}
                >
                  {moment(date).format("DD/MM/YYYY HH:mm")}
                </p>
              ))}
          </div>
          <div style={{ display: "flex", gap: 10 }}>
            <Button
              full
              title="Valider"
              onClick={() => {
                dispatch({ type: ClientAction.LOADER_APPOINTMENT });
                dispatch(
                  validateAppointment(
                    token || "",
                    licensePlate,
                    sinisterNumber,
                    garageId,
                    activeTime,
                    { ...garage }
                  )
                );
              }}
              disabled={!activeTime}
            />
            <Button full title="Annuler" onClick={() => setIsCalendar(false)} />
          </div>
        </div>
      ) : null}
      <Loader show={isLoadAppointment} text={``} />
    </div>
  );
}

export default ClientGarage;
