import { ChangeEvent, useState, useEffect, useCallback } from "react";
import { Button, InputText } from "@sahadevia/ui-kit-modern-sahadevia";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../reducers";

import { TbaseReducer } from "../../types/reducerType";

import { AuthAction, signin } from "../../actions/authAction";
import Checkbox from "../../components/UI/Checkbox";

function GarageSignIn() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isError } = useSelector((state: TbaseReducer) => state.authReducer);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRemember, setIsRemember] = useState(false);

  const _signin = () => {
    if (email && password) {
      dispatch(signin(email, password, isRemember));
    }
  };

  const resetError = useCallback(() => {
    dispatch({ type: AuthAction.RESET_ERROR });
  }, [dispatch]);

  useEffect(() => {
    resetError();
  }, [email, password, resetError]);

  return (
    <>
      <div
        className="d-flex"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <div
          className="d-flex flex-column m-auto text-center"
          style={{
            padding: 20,
            maxWidth: 440,
            width: 440,
            borderRadius: 8,
            backgroundColor: "#ffffff",
            boxShadow: "0px 24px 48px 0 rgba(0, 0, 0, 0.16)",
            gap: 20,
          }}
        >
          <h2 className="m-0" style={{ color: "var(--primary-color)" }}>
            Connexion
          </h2>
          <p className="m-0">
            Bienvenue sur ReseauCar, veuillez vous connecter pour accéder à
            votre espace personnel
          </p>
          <InputText
            type="email"
            value={email}
            placeholder="E-mail"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value);
            }}
          />
          <InputText
            type="password"
            value={password}
            placeholder="Mot de passe"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setPassword(e.target.value);
            }}
          />
          <Checkbox
            checked={isRemember}
            onClick={() => setIsRemember(!isRemember)}
            text={"Se souvenir de moi"}
          />
          {isError ? (
            <span style={{ fontSize: 14, color: "var(--red)" }}>
              Identifiant ou mot de passe incorrect
            </span>
          ) : null}
          <span
            className="cursor-pointer"
            style={{
              fontSize: 14,
              color: "var(--primary-color)",
            }}
            onClick={() => navigate("/garage/forgot")}
          >
            Mot de passe oublié
          </span>
          <div className="d-flex" style={{ gap: 10 }}>
            <Button
              text="S'inscrire"
              outline
              onClick={() => navigate("/garage/signup")}
            />
            <Button
              text="Se connecter"
              style={{
                width: "100%",
              }}
              onClick={() => _signin()}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default GarageSignIn;
