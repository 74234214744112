import star from "../../images/star.svg";
import starEmpty from "../../images/star-empty.svg";
import starHalf from "../../images/star-half.svg";

interface StarProps {
  value: number;
}

function Star({ value }: StarProps) {
  if (value >= 0) {
    const full = parseInt(value.toString());
    const half = (5 - value) % 1;
    const empty = parseInt((5 - value).toString());

    let data = [];

    for (let index = 0; index < full; index++) {
      data.push(
        <img key={`full-${index}`} src={star} alt="star" height={12} />
      );
    }

    for (let index = 0; index < half; index++) {
      data.push(
        <img key={`half-${index}`} src={starHalf} alt="star" height={12} />
      );
    }

    for (let index = 0; index < empty; index++) {
      data.push(
        <img key={`empty-${index}`} src={starEmpty} alt="star" height={12} />
      );
    }

    return data;
  } else return null;
}

export default Star;
