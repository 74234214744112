import { TclientInitialState } from "../types/client";

import { ClientAction } from "../actions/clientAction";

export const initialState = {
  isLoad: false,
  isNoExist: false,
  isAuth: false,
  isError: false,
  licensePlate: "",
  sinisterNumber: "",
  latitude: "",
  longitude: "",
  isUpload: false,
  totalImport: 0,
  countImport: 0,
  garageId: 0,
  garages: [],
  location: "",
  data: [],
  dates: [],
  appointmentStatus: 0,
  city: "",
  address1: "",
  address2: "",
  zipCode: "",
  isLoadAppointment: false,
} satisfies TclientInitialState;

export const clientReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ClientAction.CHECK_FOLDER:
      return {
        ...state,
        isNoExist: !action.success,
        licensePlate: action.body.licensePlate,
        sinisterNumber: action.body.sinisterNumber,
      };
    case ClientAction.GET_FOLDER:
      return {
        ...state,
        isAuth: action.success,
        isError: !action.success,
        licensePlate: action.body.licensePlate,
        sinisterNumber: action.body.sinisterNumber,
        city: action.data ? action.data.city : "",
        address1: action.data ? action.data.address1 : "",
        address2: action.data ? action.data.address2 : "",
        zipCode: action.data ? action.data.zipCode : "",
        garageId: action.data ? action.data.garageId : "",
      };
    case ClientAction.SET_TOTAL_IMPORT:
      return {
        ...state,
        isUpload: true,
        totalImport: action.body.totalImport + 1,
        countImport: 0,
      };
    case ClientAction.ADD_FOLDER_FILE:
      return {
        ...state,
        countImport: state.countImport + 1,
      };
    case ClientAction.UPDATE_FOLDER_PICTURES:
      return {
        ...state,
        countImport: state.countImport + 1,
      };
    case ClientAction.START_GET_GARAGES:
      return {
        ...state,
        isLoad: true,
      };
    case ClientAction.GET_GARAGES:
      return {
        ...state,
        isLoad: false,
        garages: action.data || [],
      };
    case ClientAction.GET_GARAGE:
      return {
        ...state,
        isLoad: false,
        garages: action.data || [],
      };
    case ClientAction.SET_GARAGE_ID:
      return {
        ...state,
        garageId: action.body.garageId,
      };
    case ClientAction.SET_LOCATION:
      return {
        ...state,
        location: action.body.location,
      };
    case ClientAction.GET_GARAGE_APPOINTMENTS:
      return {
        ...state,
        data: action.data,
        dates: action.dates,
      };
    case ClientAction.LOADER_APPOINTMENT:
      return {
        ...state,
        isLoadAppointment: true,
      };
    case ClientAction.VALIDATE_APPOINTMENT:
      return {
        ...state,
        isLoadAppointment: false,
        appointmentStatus: action.success ? 1 : 2,
      };
    default:
      return state;
  }
};
