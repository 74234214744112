import { ChangeEvent, useState, useEffect, useCallback } from "react";
import { Button, InputText } from "@sahadevia/ui-kit-modern-sahadevia";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../reducers";

import { TbaseReducer } from "../../types/reducerType";

import { forgotCheck, forgotUpdate } from "../../actions/authAction";

function GarageForgotPassword() {
  const { token } = useParams();
  const dispatch = useAppDispatch();

  const { forgotStatus, isError } = useSelector(
    (state: TbaseReducer) => state.authReducer
  );

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const _forgotUpdate = () => {
    if (token) {
      dispatch(forgotUpdate(token, password));
    }
  };

  const _forgotCheck = useCallback(() => {
    if (token) {
      dispatch(forgotCheck(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    _forgotCheck();
  }, [_forgotCheck]);

  if (!forgotStatus) {
    return null;
  }

  return (
    <>
      <div
        className="d-flex"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <div
          className="d-flex flex-column m-auto text-center"
          style={{
            padding: 20,
            maxWidth: 440,
            width: 440,
            borderRadius: 8,
            backgroundColor:
              forgotStatus === 2 || forgotStatus === 3
                ? "var(--primary-color)"
                : "#ffffff",
            boxShadow: "0px 24px 48px 0 rgba(0, 0, 0, 0.16)",
            gap: 20,
          }}
        >
          {forgotStatus === 2 ? (
            <span style={{ fontSize: 14, color: "#ffffff" }}>
              Le lien de rénitialisation n'est pas valide
            </span>
          ) : forgotStatus === 3 ? (
            <span style={{ fontSize: 14, color: "#ffffff" }}>
              Le mot de passe a bien été modifié
            </span>
          ) : (
            <>
              <h2 className="m-0" style={{ color: "var(--primary-color)" }}>
                Rénitialisation du mot de passe
              </h2>
              <InputText
                type="password"
                value={password}
                placeholder="Nouveau mot de passe"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setPassword(e.target.value);
                }}
              />
              <InputText
                type="password"
                value={confirmPassword}
                placeholder="Confirmer le mot de passe"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setConfirmPassword(e.target.value);
                }}
              />
              {isError ? (
                <span style={{ color: "var(--red)" }}>
                  Une erreur est survenue
                </span>
              ) : null}
              <div className="d-flex" style={{ gap: 10 }}>
                <Button
                  disabled={!password || password !== confirmPassword}
                  text="Valider"
                  style={{
                    width: "100%",
                  }}
                  onClick={() => _forgotUpdate()}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default GarageForgotPassword;
