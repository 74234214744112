import { useCallback, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./App.css";

import Index from "./views/Index";

import ClientAuth from "./views/client/ClientAuth";
import ClientPictures from "./views/client/ClientPictures";
import ClientPicturesSuccess from "./views/client/ClientPicturesSuccess";
import ClientGarages from "./views/client/ClientGarages";
import ClientGaragesAo from "./views/client/ClientGaragesAo";

import GarageDesktop from "./layouts/GarageDesktop";
import GarageAuth from "./layouts/GarageAuth";

import GarageSignIn from "./views/garage/GarageSignIn";
import GarageSignUp from "./views/garage/GarageSignUp";
import GarageForgot from "./views/garage/GarageForgot";
import GarageForgotPassword from "./views/garage/GarageForgotPassword";
import GarageDashboard from "./views/garage/GarageDashboard";
import GarageAppointments from "./views/garage/GarageAppointments";
import GarageTenders from "./views/garage/GarageTenders";
import GarageTender from "./views/garage/GarageTender";
import GarageAccount from "./views/garage/GarageAccount";

import { useAppDispatch } from "./reducers";

import { refreshToken } from "./actions/authAction";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
  },
  {
    path: "pictures-success",
    element: <ClientPicturesSuccess />,
  },
  {
    path: "client",
    element: <ClientAuth />,
    children: [
      {
        path: "pictures/:token",
        element: <ClientPictures />,
      },
      {
        path: "garages/:token",
        element: <ClientGarages />,
      },
      {
        path: "garages-ao/:token",
        element: <ClientGaragesAo />,
      },
    ],
  },
  {
    path: "garage",
    element: <GarageDesktop />,
    children: [
      {
        path: "",
        element: <GarageDashboard />,
      },
      {
        path: "account",
        element: <GarageAccount />,
      },
      {
        path: "appointments",
        element: <GarageAppointments />,
      },
      {
        path: "tenders",
        element: <GarageTenders />,
      },
      {
        path: "tender/:folderId",
        element: <GarageTender />,
      },
    ],
  },
  {
    path: "garage",
    element: <GarageAuth />,
    children: [
      {
        path: "signin",
        element: <GarageSignIn />,
      },
      {
        path: "signup",
        element: <GarageSignUp />,
      },
      {
        path: "forgot",
        element: <GarageForgot />,
      },
      {
        path: "forgot/:token",
        element: <GarageForgotPassword />,
      },
    ],
  },
]);

function App() {
  const dispatch = useAppDispatch();

  const _refreshToken = useCallback(() => {
    dispatch(refreshToken());
  }, [dispatch]);

  useEffect(() => {
    _refreshToken();
  }, [_refreshToken]);

  return <RouterProvider router={router} />;
}

export default App;
