import { useCallback, useEffect, useState } from "react";
import { Button } from "@sahadevia/ui-kit-modern-sahadevia";
import { Col, Row } from "react-flexbox-grid";
import { useSelector } from "react-redux";
import moment from "moment";

import Card from "../../components/garage/Card";
import ModalViewer from "../../components/garage/ModalViewer";

import { useAppDispatch } from "../../reducers";

import { getTendersHistory } from "../../actions/garageAction";

import { ROUTE_API } from "../../constants";
import { TbaseReducer } from "../../types/reducerType";
import { TenderHistory } from "../../types/garage";

interface StatusProps {
  status: number;
  tenderStatus: number;
}

function Status({ status, tenderStatus }: StatusProps) {
  if (status === 6) {
    return (
      <>
        <div
          className="bg-orange white text-center"
          style={{ padding: 5, borderRadius: 6, fontSize: 14 }}
        >
          En attente
        </div>
      </>
    );
  } else if (tenderStatus === 1 && status === 7) {
    return (
      <>
        <div
          className="bg-green white text-center"
          style={{ padding: 5, borderRadius: 6, fontSize: 14 }}
        >
          Attente RDV
        </div>
      </>
    );
  } else if (tenderStatus === 1) {
    return (
      <>
        <div
          className="bg-green white text-center"
          style={{ padding: 5, borderRadius: 6, fontSize: 14 }}
        >
          Validé
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          className="bg-red white text-center"
          style={{ padding: 5, borderRadius: 6, fontSize: 14 }}
        >
          Expiré
        </div>
      </>
    );
  }
}

function GarageTenders() {
  const dispatch = useAppDispatch();

  const { id } = useSelector((state: TbaseReducer) => state.authReducer);
  const { tendersHistory } = useSelector(
    (state: TbaseReducer) => state.garageReducer
  );

  const _getTendersHistory = useCallback(() => {
    if (id) {
      dispatch(getTendersHistory(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    _getTendersHistory();
  }, [id, _getTendersHistory]);

  const [currentPath, setCurrentPath] = useState("");

  return (
    <>
      <Row style={{ display: "flex", rowGap: 20 }}>
        <Col xs={12}>
          <Card title="Historique offres" noBodyPadding noHeight>
            {tendersHistory.length ? (
              <table className="table-tender">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date d'expiration</th>
                    <th>Réf</th>
                    <th>Marque</th>
                    <th>Modèle</th>
                    <th>Immatriculation</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {tendersHistory.map((element: TenderHistory, idx: number) => (
                    <tr key={idx}>
                      <td></td>
                      <td>{moment(element.date).format("DD/MM/YYYY")}</td>
                      <td>{element.sinisterNumber}</td>
                      <td>{element.model}</td>
                      <td>{element.model}</td>
                      <td>{element.licensePlate}</td>
                      <td>
                        <Status
                          status={element.status}
                          tenderStatus={element.tenderStatus}
                        />
                      </td>
                      <td>
                        <Button
                          text="Visualier mon offre"
                          onClick={() =>
                            setCurrentPath(
                              `${ROUTE_API}/garage/get-file/${element.fileId}/${
                                element.fileDate.split("T")[0]
                              }/pdf`
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  minHeight: 200,
                }}
              >
                <span>Aucun appel d'offres en attente</span>
              </div>
            )}
          </Card>
        </Col>
      </Row>
      <ModalViewer
        path={currentPath}
        onClose={() => setCurrentPath("")}
        title="Appel d'offres"
      />
    </>
  );
}

export default GarageTenders;
