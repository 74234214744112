import { TloaderInitialState } from "../types/loader";

export const initialState = {
  countLoader: 0,
  isLoadPrices: false,
} satisfies TloaderInitialState;

export enum LoaderAction {
  START_LOAD = "START_LOAD",
  STOP_LOAD = "STOP_LOAD",
  START_LOAD_PRICES = "START_LOAD_PRICES",
  STOP_LOAD_PRICES = "STOP_LOAD_PRICES",
}

export const loaderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LoaderAction.START_LOAD:
      return {
        ...state,
        countLoader: state.countLoader >= 0 ? state.countLoader + 1 : 0,
      };
    case LoaderAction.STOP_LOAD:
      return {
        ...state,
        countLoader: state.countLoader - 1,
      };
    case LoaderAction.START_LOAD_PRICES:
      return {
        ...state,
        isLoadPrices: true,
      };
    case LoaderAction.STOP_LOAD_PRICES:
      return {
        ...state,
        isLoadPrices: false,
      };
    default:
      return state;
  }
};
