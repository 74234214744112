import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col } from "react-flexbox-grid";
import { Icon } from "@sahadevia/ui-kit-modern-sahadevia";
import moment from "moment";

import Card from "../../components/garage/Card";
import ModalViewer from "./ModalViewer";

import { updateAppointment } from "../../actions/garageAction";

import { useAppDispatch } from "../../reducers";

import { ROUTE_API } from "../../constants";
import { TbaseReducer } from "../../types/reducerType";

interface ItemProps {
  name: string;
  value: string;
}

function Appointments() {
  let { appointments } = useSelector(
    (state: TbaseReducer) => state.garageReducer
  );

  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState(0);
  const [currentPath, setCurrentPath] = useState("");

  const _setCurrentPage = useCallback(() => {
    if (appointments.length && appointments.length < currentPage + 1) {
      setCurrentPage(appointments.length - 1);
    }
  }, [appointments, currentPage]);

  useEffect(() => {
    _setCurrentPage();
  }, [appointments, _setCurrentPage]);

  const Item = ({ name, value }: ItemProps) => {
    return (
      <div className="d-flex flex-column">
        <span style={{ color: "var(--primary-color)", fontSize: 14 }}>
          {name}
        </span>
        <span
          style={{
            fontSize: 14,
          }}
        >
          {value}
        </span>
      </div>
    );
  };

  return (
    <>
      <Col xs={12} md={12} lg={3}>
        <Card
          title="RDV à valider"
          rightHeader={
            <span className="mt-auto" style={{ color: "#fff" }}>
              {appointments.length}
            </span>
          }
          style={{
            backgroundColor: "var(--primary-color)",
            color: "#fff",
          }}
        >
          <>
            {appointments.length && appointments.length >= currentPage + 1 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                  gap: 10,
                }}
              >
                <div
                  className="d-flex flex-column"
                  style={{
                    position: "relative",
                    backgroundColor: "#fff",
                    color: "var(--text-color)",
                    borderRadius: 8,
                    padding: 10,
                    width: "100%",
                    gap: 6,
                    height: "100%",
                  }}
                >
                  <div className="d-flex flex-column">
                    <span style={{ fontSize: 16 }}>
                      {moment(appointments[currentPage].date).format(
                        "DD/MM/YYYY"
                      )}
                    </span>
                    <span
                      style={{ fontSize: 22, color: "var(--primary-color)" }}
                    >
                      {moment(appointments[currentPage].date).format("HH:mm")}
                    </span>
                  </div>
                  <Item
                    name="N° Sinistre"
                    value={appointments[currentPage].sinisterNumber}
                  />
                  <Item
                    name="Immat"
                    value={appointments[currentPage].licensePlate}
                  />
                  <Item name="Marque" value={appointments[currentPage].brand} />
                  <Item name="Modèle" value={appointments[currentPage].model} />
                  <a
                    href="#/"
                    className="mt-auto"
                    style={{
                      color: "var(--primary-color)",
                      fontSize: 14,
                    }}
                    onClick={() => {
                      setCurrentPath(
                        `${ROUTE_API}/garage/get-file/${
                          appointments[currentPage].fileId
                        }/${
                          appointments[currentPage].fileDate.split("T")[0]
                        }/pdf`
                      );
                    }}
                  >
                    Dossier photos
                  </a>
                  <div
                    style={{
                      position: "absolute",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      flexDirection: "column",
                      padding: 10,
                      gap: 12,
                    }}
                  >
                    <Icon
                      size={24}
                      icon={"Check"}
                      color="#fff"
                      style={{
                        marginLeft: "auto",
                        backgroundColor: "var(--primary-color)",
                        borderRadius: 24,
                        padding: 10,
                      }}
                      onClick={() =>
                        dispatch(
                          updateAppointment(appointments[currentPage].id, 4)
                        )
                      }
                    />
                    <Icon
                      size={14}
                      icon={"Cross"}
                      color="var(--primary-color)"
                      style={{
                        marginLeft: "auto",
                        border: "solid 1px var(--primary-color)",
                        borderRadius: 24,
                        padding: 10,
                      }}
                      onClick={() =>
                        dispatch(
                          updateAppointment(appointments[currentPage].id, 5)
                        )
                      }
                    />
                  </div>
                </div>
                <div
                  className="d-flex"
                  style={{
                    gap: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    style={{ padding: 0 }}
                    icon={"CaretLeft"}
                    size={14}
                    color="#fff"
                    disabled={!currentPage}
                    onClick={() => setCurrentPage(currentPage - 1)}
                  />
                  <span style={{ fontSize: 14, color: "#fff" }}>
                    {currentPage + 1}/{appointments.length}
                  </span>
                  <Icon
                    style={{ padding: 0 }}
                    icon={"CaretRight"}
                    size={14}
                    color="#fff"
                    disabled={appointments.length - 1 === currentPage}
                    onClick={() => setCurrentPage(currentPage + 1)}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <span style={{ color: "#fff" }}>Vous êtes à jour</span>
              </div>
            )}
          </>
        </Card>
      </Col>

      <ModalViewer
        path={currentPath}
        onClose={() => setCurrentPath("")}
        title="Rapport photos"
      />
    </>
  );
}

export default Appointments;
