import { ChangeEvent, useState, useEffect, useCallback } from "react";
import { Button, InputText } from "@sahadevia/ui-kit-modern-sahadevia";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../reducers";

import { TbaseReducer } from "../../types/reducerType";

import { AuthAction, forgot } from "../../actions/authAction";

function GarageForgot() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isError, isSuccess } = useSelector(
    (state: TbaseReducer) => state.authReducer
  );

  const [email, setEmail] = useState("");

  const _forgot = () => {
    if (email) {
      dispatch(forgot(email));
    }
  };

  const resetError = useCallback(() => {
    dispatch({ type: AuthAction.RESET_ERROR });
  }, [dispatch]);

  useEffect(() => {
    resetError();
  }, [email, resetError]);

  return (
    <>
      <div
        className="d-flex"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <div
          className="d-flex flex-column m-auto text-center"
          style={{
            padding: 20,
            maxWidth: 440,
            width: 440,
            borderRadius: 8,
            backgroundColor: isSuccess ? "var(--primary-color)" : "#ffffff",
            boxShadow: "0px 24px 48px 0 rgba(0, 0, 0, 0.16)",
            gap: 20,
          }}
        >
          {isSuccess ? (
            <span style={{ fontSize: 14, color: "#ffffff" }}>
              Un email a été envoyé à l'adresse <b>{email}</b> pour vous
              permettre de rénitialiser votre mot de passe
            </span>
          ) : (
            <>
              <h2 className="m-0" style={{ color: "var(--primary-color)" }}>
                Mot de passe oublié
              </h2>
              <InputText
                type="email"
                value={email}
                placeholder="E-mail"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.target.value);
                }}
              />
              {isError ? (
                <span style={{ color: "var(--red)" }}>Adresse inconnue</span>
              ) : null}
              <div className="d-flex" style={{ gap: 10 }}>
                <Button
                  text="Retour"
                  outline
                  onClick={() => navigate("/garage/signin")}
                />
                <Button
                  text="Continuer"
                  style={{
                    width: "100%",
                  }}
                  onClick={() => _forgot()}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default GarageForgot;
