import { ChangeEvent } from "react";

type InputTextProps = {
  inputRef?: React.MutableRefObject<undefined>;
  title: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  required?: boolean;
};

function InputText({
  inputRef,
  title,
  placeholder,
  value,
  onChange,
  required,
}: InputTextProps) {
  return (
    <div className="input-text">
      <span>
        {title}
        {required ? <label className="required">*</label> : null}
      </span>
      <input
        // @ts-ignore
        ref={inputRef}
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value);
        }}
        type="text"
        placeholder={placeholder}
      />
    </div>
  );
}

export default InputText;
