import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import Header from "../../components/UI/header/Header";
import Loader from "../../components/UI/Loader";

import ClientGarage from "./ClientGarage";

import { getGarage } from "../../actions/clientAction";

import { useAppDispatch } from "../../reducers";
import { TbaseReducer } from "../../types/reducerType";

function ClientGarages() {
  const dispatch = useAppDispatch();

  let { isLoad, garages, garageId } = useSelector(
    (state: TbaseReducer) => state.clientReducer
  );

  const _getGarage = useCallback(() => {
    if (garageId) {
      dispatch(getGarage(garageId));
    }
  }, [garageId, dispatch]);

  useEffect(() => {
    _getGarage();
  }, [garageId, _getGarage]);

  return (
    <>
      <Header
        title={"Prise de RDV"}
        currentPage={0}
        totalPage={0}
        incrementPercentage={0}
      />

      {garageId && garages.length ? <ClientGarage isBack={false} /> : null}
      <Loader show={isLoad} text={`Recherche en cours`} />
    </>
  );
}

export default ClientGarages;
