type ButtonProps = {
  title: string;
  onClick: React.MouseEventHandler<HTMLElement>;
  white?: boolean;
  full?: boolean;
  disabled?: boolean;
};

function Button({ title, onClick, white, disabled, full }: ButtonProps) {
  return (
    <>
      <button
        className={`button-picture ${
          disabled ? "button-picture-disabled" : ""
        }`}
        style={{
          ...(full ? { width: "100%", justifyContent: "center" } : {}),
          ...(white ? { backgroundColor: "#fff" } : {}),
        }}
        onClick={!disabled ? onClick : () => {}}
      >
        <span
          style={
            white
              ? { backgroundColor: "#fff", color: "var(--primary-color)" }
              : {}
          }
        >
          {title}
        </span>
      </button>
    </>
  );
}

export default Button;
