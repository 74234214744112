import scoreA from "../../images/A.svg";
import scoreB from "../../images/B.svg";
import scoreC from "../../images/C.svg";
import scoreD from "../../images/D.svg";
import scoreE from "../../images/E.svg";

interface ProjectEProps {
  value: number;
}

function ProjectE({ value }: ProjectEProps) {
  if (value > 50) {
    return <img alt="B" height={32} src={scoreA} />;
  } else if (value > 40) {
    return <img alt="B" height={32} src={scoreB} />;
  } else if (value > 30) {
    return <img alt="B" height={32} src={scoreC} />;
  } else if (value > 20) {
    return <img alt="B" height={32} src={scoreD} />;
  } else {
    return <img alt="B" height={32} src={scoreE} />;
  }
}

export default ProjectE;
