import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Header from "../../components/UI/header/Header";
import Paragraph from "../../components/UI/Paragraph";
import Dropdown from "../../components/UI/Dropdown";
import Loader from "../../components/UI/Loader";

import logo from "../../images/logo-bleu.png";
import headerIllustration from "../../images/header-illustration.png";

import Garage from "../../components/client/Garage";
import CardHeader from "../../components/client/CardHeader";
import AutoComplete from "../../components/client/AutoComplete";

import ClientGarage from "./ClientGarage";
import { TbaseReducer } from "../../types/reducerType";

import { Garage as GarageType } from "../../types/client";

function ClientGarages() {
  let { isLoad, garages, garageId, sinisterNumber } = useSelector(
    (state: TbaseReducer) => state.clientReducer
  );

  const [currentPage, setCurrentPage] = useState(0);
  // const [isMap, setIsMap] = useState(false);
  const [sort, setSort] = useState("price");

  const totalPage = 3;

  useEffect(() => {
    if (garages.length) {
      setCurrentPage(1);
    }
  }, [garages]);

  let minPrice = null;
  let minDistance = null;
  let bestRank = null;
  let bestProjectE = null;

  for (let index = 0; index < garages.length; index++) {
    const garage = garages[index];

    if (!minPrice || garage.price < garages[minPrice].price) {
      minPrice = index;
    }

    if (!minDistance || garage.distance < garages[minDistance].distance) {
      minDistance = index;
    }

    if (!bestRank || garage.rank > garages[bestRank].rank) {
      bestRank = index;
    }

    if (!bestProjectE || garage.projectE > garages[bestProjectE].projectE) {
      bestProjectE = index;
    }
  }

  //@ts-ignore
  const tmpGarages = [...garages].sort((a: GarageType, b: GarageType) => {
    if (sort === "price") {
      return a.price - b.price;
    } else if (sort === "distance") {
      return a.distance - b.distance;
    } else if (sort === "projectE") {
      return b.projectE - a.projectE;
    } else if (sort === "rank") {
      return b.rank - a.rank;
    } else {
      return true;
    }
  });

  return (
    <>
      <Header
        title={"Recherche de réparateur"}
        currentPage={currentPage}
        totalPage={totalPage}
        incrementPercentage={0}
        noBg
      />

      {currentPage === 0 ? (
        <>
          <img
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              mixBlendMode: "multiply",
            }}
            height={120}
            alt="ReseauCar"
            src={logo}
          />
          <h2 style={{ color: "var(--primary-color)", textAlign: "center" }}>
            Recherchez des réparateurs près de chez vous
          </h2>
          <Paragraph
            reference=""
            variables={[]}
            text="Entrez votre localisation et trouvez des réparateurs près de chez
          vous, comparez les prix et prenez rendez-vous quand vous le souhaitez"
          />
          {sinisterNumber ? <AutoComplete isFocus={true} /> : null}
        </>
      ) : null}

      {currentPage === 1 ? (
        <>
          <img
            src={headerIllustration}
            alt=""
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              width: "100%",
            }}
          />
          <AutoComplete />
          <div style={{ display: "flex", gap: 8, zIndex: 10 }}>
            <Dropdown
              white
              title="Trier par"
              active={sort}
              handleSort={(sort: string) => {
                setSort(sort);
              }}
            />
            {/* <Button
              white
              title={isMap ? "Voir la liste" : "Voir la carte"}
              onClick={() => setIsMap(!isMap)}
            /> */}
          </div>
          {/* {isMap ? (
            <>
              <div style={{ margin: -20, marginTop: -120, height: "100%", zIndex: -1 }}>
                <iframe
                  title="Map Google"
                  style={{ border: 0, height: "100%", width: "100%" }}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAu0mZkek1vPk-2o2bmU0WqV0MTdbhvA8I&q=Space+Needle,Seattle+WA"
                />
              </div>
            </>
          ) : ( */}
          <>
            <div style={{ display: "flex", gap: 8, zIndex: 1 }}>
              <CardHeader
                text="Le moins cher"
                price={
                  minPrice !== null && garages.hasOwnProperty(minPrice)
                    ? garages[minPrice].price
                    : 0
                }
                color="#3597D3"
              />
              <CardHeader
                text="Le plus proche"
                price={
                  minDistance !== null && garages.hasOwnProperty(minDistance)
                    ? garages[minDistance].price
                    : 0
                }
                color="#FFC90B"
              />
            </div>
            <div style={{ display: "flex", gap: 8, zIndex: 1 }}>
              <CardHeader
                text="Le plus vert"
                price={
                  bestProjectE !== null && garages.hasOwnProperty(bestProjectE)
                    ? garages[bestProjectE].price
                    : 0
                }
                color="#53D426"
              />
              <CardHeader
                text="Le mieux noté"
                price={
                  bestRank !== null && garages.hasOwnProperty(bestRank)
                    ? garages[bestRank].price
                    : 0
                }
                color="#FD6D4E"
              />
            </div>
            <p>{garages.length} résultats</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                height: "100%",
                overflow: "auto",
                zIndex: 1,
                backgroundColor: "#fff",
                borderRadius: 10,
              }}
            >
              {tmpGarages.map((garage: any) => (
                <Garage
                  key={garage.id}
                  id={garage.id}
                  name={garage.name}
                  price={garage.price}
                  distance={garage.distance}
                  nextAppointment={garage.nextAppointment}
                  projectE={garage.projectE}
                  rank={garage.rank}
                />
              ))}
            </div>
          </>
          {/* )} */}
        </>
      ) : null}
      {garageId ? <ClientGarage /> : null}
      <Loader show={isLoad} text={`Recherche en cours`} />
    </>
  );
}

export default ClientGarages;
