import { ChangeEventHandler } from "react";

interface InputProps {
  value: string;
  placeholder?: string;
  isPercentage?: boolean;
  isCurrency?: boolean;
  isFull?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  type?: string;
  disabled?: boolean;
}

const Input = ({
  value,
  placeholder,
  onChange,
  type = "text",
  isCurrency,
  isPercentage,
  isFull,
  disabled,
}: InputProps) => {
  return (
    <div
      style={{
        display: "inline-flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 8,
        ...(isFull ? { width: "100%" } : {}),
      }}
    >
      <div
        style={{
          display: "inline-flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: "solid #0095ae 1px",
          borderRadius: 6,
          padding: 8,
          ...(isFull ? { width: "100%" } : {}),
        }}
      >
        <input
          disabled={disabled}
          type={type}
          placeholder={placeholder}
          style={{
            color: "#3597D3",
            border: "none",
            width: "100%",
          }}
          value={value}
          onChange={onChange}
        />
        {isPercentage ? <span>%</span> : null}
        {isCurrency ? <span>€</span> : null}
      </div>
    </div>
  );
};

export default Input;
