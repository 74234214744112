import { useRef, useState } from "react";
// @ts-ignore
import SignatureCanvas from "react-signature-canvas";

import plus from "../../images/plus.svg";
import check from "../../images/check.svg";

type ButtonSignatureProps = {
  value: string;
  onChange: (value: string) => void;
  required?: boolean;
};

function ButtonSignature({ required, onChange, value }: ButtonSignatureProps) {
  const signatureCanvasRef = useRef();

  const [isModal, setIsModal] = useState(false);
  const [currentSignature, setCurrentSignature] = useState<string>(value);

  return (
    <>
      <div className="button-picture-container">
        {currentSignature ? (
          <>
            <div className="button-picture-viewer">
              <img src={currentSignature} alt="signature" />
            </div>
          </>
        ) : null}
        <button className="button-signature" onClick={() => setIsModal(true)}>
          <span>Signature {required ? "(Obligatoire)" : "(Facultatif)"}</span>
          <img alt="plus" src={currentSignature ? check : plus} />
        </button>
      </div>
      {isModal ? (
        <div className="button-signature-modal-container">
          <div className="button-signature-modal">
            <SignatureCanvas
              ref={signatureCanvasRef}
              penColor="black"
              canvasProps={{ width: 300, height: 200, className: "sigCanvas" }}
              backgroundColor="#fff"
            />

            <div>
              <button
                className="button-signature outline"
                onClick={() => {
                  // @ts-ignore
                  signatureCanvasRef.current.clear();
                  setCurrentSignature("");
                  onChange("");
                }}
              >
                Effacer
              </button>
              <button
                className="button-signature"
                onClick={() => {
                  // @ts-ignore
                  if (!signatureCanvasRef.current.isEmpty()) {
                    // @ts-ignore
                    let base64 = signatureCanvasRef.current.toDataURL();
                    setCurrentSignature(base64);
                    onChange(base64);
                  }
                  setIsModal(false);
                }}
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default ButtonSignature;
