import { useRef, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import InputText from "../../components/UI/InputText";

import { useAppDispatch } from "../../reducers";

import { ClientAction, getGarages } from "../../actions/clientAction";
import { TbaseReducer } from "../../types/reducerType";

interface AutoCompleteProps {
  isFocus?: boolean;
}

function AutoComplete({ isFocus }: AutoCompleteProps) {
  const dispatch = useAppDispatch();

  const { token } = useParams();

  let { location, city, zipCode, licensePlate, sinisterNumber } = useSelector(
    (state: TbaseReducer) => state.clientReducer
  );

  const autoCompleteRef = useRef();
  const inputRef = useRef();

  const handleCityChange = useCallback(() => {
    if (city && !location) {
      dispatch({
        type: ClientAction.SET_LOCATION,
        body: {
          location: `${zipCode} ${city}`,
        },
      });
    }
  }, [dispatch, city, zipCode, location]);

  useEffect(() => {
    handleCityChange();
  }, [city, handleCityChange]);

  const locationChange = useCallback(() => {
    if (isFocus) {
      setTimeout(() => {
        // @ts-ignore
        inputRef.current.focus();
      }, 1000);
    }
  }, [isFocus]);

  useEffect(() => {
    locationChange();
  }, [location, locationChange]);

  const handlePlaceChanged = useCallback(() => {
    // @ts-ignore
    if (autoCompleteRef.current.getPlace()) {
      // @ts-ignore
      const latitude = autoCompleteRef.current
        .getPlace()
        .geometry.location.lat();
      // @ts-ignore
      const longitude = autoCompleteRef.current
        .getPlace()
        .geometry.location.lng();

      dispatch({
        type: ClientAction.SET_LOCATION,
        body: {
          // @ts-ignore
          location: autoCompleteRef.current.getPlace().formatted_address,
        },
      });
      dispatch({ type: ClientAction.START_GET_GARAGES });

      dispatch(
        getGarages(
          latitude,
          longitude,
          10,
          token || "",
          licensePlate,
          sinisterNumber
        )
      );
    }
  }, [dispatch, token, licensePlate, sinisterNumber]);

  useEffect(() => {
    const options = {
      componentRestrictions: { country: "fr" },
      fields: ["formatted_address", "geometry"],
      types: ["locality", "postal_code"],
    };

    // @ts-ignore
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    // @ts-ignore
    autoCompleteRef.current.addListener("place_changed", () => {
      handlePlaceChanged();
    });
  }, [handlePlaceChanged]);

  return (
    <InputText
      title={""}
      placeholder={"Code postal, ville, adresse"}
      value={location}
      onChange={(value) => {
        dispatch({
          type: ClientAction.SET_LOCATION,
          body: { location: value },
        });
      }}
      required={false}
      inputRef={inputRef}
    />
  );
}

export default AutoComplete;
