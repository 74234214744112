import { _fetch } from "../utils/fetch";

import { AppDispatch } from "../store";

export enum AuthAction {
  REFRESH_TOKEN = "AUTH_REFRESH_TOKEN",
  SIGNIN = "AUTH_SIGNIN",
  SIGNUP = "AUTH_SIGNUP",
  SIGNOUT = "AUTH_SIGNOUT",
  FORGOT = "AUTH_FORGOT",
  FORGOT_CHECK = "AUTH_FORGOT_CHECK",
  FORGOT_UPDATE = "AUTH_FORGOT_UPDATE",
  RESET_ERROR = "AUTH_RESET_ERROR",
  UPDATE_DATA = "AUTH_UPDATE_DATA",
  UPDATE_ACCOUNT = "AUTH_UPDATE_ACCOUNT",
  UPDATE_ACCOUNT_PICTURE = "AUTH_UPDATE_ACCOUNT_PICTURE",
  UPDATE_PASSWORD = "AUTH_UPDATE_PASSWORD",
  UPDATE_PRICES = "AUTH_UPDATE_PRICES",
  UPDATE_SERVICES = "AUTH_UPDATE_SERVICES",
  UPDATE_DAYS = "AUTH_UPDATE_DAYS",
  UPDATE_TIMES = "AUTH_UPDATE_TIMES",
  UPDATE_SLOT = "AUTH_UPDATE_SLOT",
  UPDATE_AVAILABILITIES = "AUTH_UPDATE_AVAILABILITIES",
}

export const refreshToken = (token?: string) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/auth/refresh-token",
      AuthAction.REFRESH_TOKEN,
      JSON.stringify({ token }),
      dispatch
    );
  };
};

export const signin = (
  email: string,
  password: string,
  isRemember: boolean
) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/auth/signin",
      AuthAction.SIGNIN,
      JSON.stringify({ email, password, isRemember }),
      dispatch
    );
  };
};

export const signup = (
  email: string,
  password: string,
  name: string,
  phone: string,
  siret: string
) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/auth/signup",
      AuthAction.SIGNUP,
      JSON.stringify({ email, password, name, phone, siret }),
      dispatch
    );
  };
};

export const signout = () => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/auth/signout",
      AuthAction.SIGNOUT,
      JSON.stringify({}),
      dispatch
    );
  };
};

export const forgot = (email: string) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/auth/forgot",
      AuthAction.FORGOT,
      JSON.stringify({ email }),
      dispatch
    );
  };
};

export const forgotCheck = (token: string) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/auth/forgot-check",
      AuthAction.FORGOT_CHECK,
      JSON.stringify({ token }),
      dispatch
    );
  };
};

export const forgotUpdate = (token: string, password: string) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/auth/forgot-update",
      AuthAction.FORGOT_UPDATE,
      JSON.stringify({ token, password }),
      dispatch
    );
  };
};

export const updatePrices = (data: any) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/auth/update-prices",
      AuthAction.UPDATE_PRICES,
      JSON.stringify({ data }),
      dispatch
    );
  };
};

export const updateAccount = (data: any) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/auth/update-account",
      AuthAction.UPDATE_ACCOUNT,
      JSON.stringify({ data }),
      dispatch
    );
  };
};

export const updateAccountPicture = (base64: string, type: string) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/auth/update-account-picture",
      AuthAction.UPDATE_ACCOUNT_PICTURE,
      JSON.stringify({ base64, type }),
      dispatch
    );
  };
};

export const updatePassword = (oldPassword: string, newPassword: string) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/auth/update-password",
      AuthAction.UPDATE_PASSWORD,
      JSON.stringify({ oldPassword, newPassword }),
      dispatch
    );
  };
};

export const updateServices = (services: Array<string>) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/auth/update-services",
      AuthAction.UPDATE_SERVICES,
      JSON.stringify({ services }),
      dispatch
    );
  };
};

export const updateAvailabilities = (
  days: Array<number>,
  times: Array<any>,
  slot: number
) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/auth/update-availabilities",
      AuthAction.UPDATE_AVAILABILITIES,
      JSON.stringify({ days, times, slot }),
      dispatch
    );
  };
};
