import { _fetch } from "../utils/fetch";

import { Discounts } from "../types/garage";

import { LoaderAction } from "../reducers/loaderReducer";

import { AppDispatch } from "../store";

export enum GarageAction {
  SET_PATH_TENDER = "SET_PATH_TENDER",
  GET_EMPTY_APPOINTMENTS = "GET_EMPTY_APPOINTMENTS",
  GET_APPOINTMENTS = "GET_APPOINTMENTS",
  UPDATE_APPOINTMENTS = "UPDATE_APPOINTMENTS",
  GET_TENDERS = "GET_TENDERS",
  GET_TENDER = "GET_TENDER",
  INSERT_TENDER = "INSERT_TENDER",
  GET_TENDERS_HISTORY = "GET_TENDERS_HISTORY",
  GET_APPOINTMENTS_HISTORY = "GET_APPOINTMENTS_HISTORY",
  GET_PRICES_AROUND = "GET_PRICES_AROUND",
  INSERT_EMPTY_APPOINTMENTS = "INSERT_EMPTY_APPOINTMENTS",
  DELETE_EMPTY_APPOINTMENTS = "DELETE_EMPTY_APPOINTMENTS",
}

export const getEmptyAppointments = (garageId: number) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/get-empty-appointments",
      GarageAction.GET_EMPTY_APPOINTMENTS,
      JSON.stringify({ garageId }),
      dispatch
    );
  };
};

export const insertEmptyAppointment = (date: string) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/insert-empty-appointment",
      GarageAction.INSERT_EMPTY_APPOINTMENTS,
      JSON.stringify({ date }),
      dispatch
    );
  };
};

export const deleteEmptyAppointment = (id: number) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/delete-empty-appointment",
      GarageAction.DELETE_EMPTY_APPOINTMENTS,
      JSON.stringify({ id }),
      dispatch
    );
  };
};

export const getAppointments = (garageId: number) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/get-appointments",
      GarageAction.GET_APPOINTMENTS,
      JSON.stringify({ garageId }),
      dispatch
    );
  };
};

export const updateAppointment = (folderId: number, status: number) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/update-appointments",
      GarageAction.UPDATE_APPOINTMENTS,
      JSON.stringify({
        folderId,
        status,
      }),
      dispatch
    );
  };
};

export const getTenders = (garageId: number) => {
  return async (dispatch: AppDispatch) => {
    await _fetch(
      "/api/garage/get-tenders",
      GarageAction.GET_TENDERS,
      JSON.stringify({ garageId }),
      dispatch
    );
  };
};

export const getTender = (folderId: number) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/get-tender",
      GarageAction.GET_TENDER,
      JSON.stringify({
        folderId,
      }),
      dispatch
    );
  };
};

export const insertTender = (
  folderId: number,
  garageId: number,
  parts: Array<any>,
  srgc: Array<any>,
  hours: Array<any>,
  discounts: Discounts
) => {
  return async (dispatch: AppDispatch) => {
    await _fetch(
      "/api/garage/insert-tender",
      GarageAction.INSERT_TENDER,
      JSON.stringify({
        folderId,
        garageId,
        parts,
        srgc,
        hours,
        discounts,
      }),
      dispatch
    );
  };
};

export const getTendersHistory = (garageId: number) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/get-tenders-history",
      GarageAction.GET_TENDERS_HISTORY,
      JSON.stringify({ garageId }),
      dispatch
    );
  };
};

export const getAppointmentsHistory = (garageId: number) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/get-appointments-history",
      GarageAction.GET_APPOINTMENTS_HISTORY,
      JSON.stringify({ garageId }),
      dispatch
    );
  };
};

export const getPricesAround = (garageId: number) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/garage/get-prices-around",
      GarageAction.GET_PRICES_AROUND,
      JSON.stringify({ garageId }),
      dispatch,
      LoaderAction.START_LOAD_PRICES,
      LoaderAction.STOP_LOAD_PRICES
    );
  };
};
