import { useCallback, useEffect, useState } from "react";
import { Button } from "@sahadevia/ui-kit-modern-sahadevia";
import { Row, Col } from "react-flexbox-grid";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

import Card from "../../components/garage/Card";
import Input from "../../components/garage/Input";
import ModalViewer from "../../components/garage/ModalViewer";

import { useAppDispatch } from "../../reducers";

import {
  GarageAction,
  getTender,
  getTenders,
  insertTender,
} from "../../actions/garageAction";

import { getCurrencyValue, getPercentageValue } from "../../utils/general";
import { TbaseReducer } from "../../types/reducerType";
import { Hours, Hour, Part, SRGC } from "../../types/garage";

const getValue = (value: number, percentages: Array<number>) => {
  percentages.forEach((percentage) => {
    if (percentage) {
      value = value - (value * percentage) / 100;
    }
  });

  return value;
};

const getTotal = (data: Array<any>, names?: Array<string>) => {
  let value = 0;

  if (names && names.length) {
    // @ts-ignore
    data = data.filter((element) => names.includes(element.name));
  }

  data.forEach((element) => {
    value += getValue(
      element.hour
        ? parseFloat(element.hour) * parseFloat(element.price || 0)
        : parseFloat(element.price || element.total),
      [parseFloat(element.discount), parseFloat(element.dilapidated)]
    );
  });

  return value;
};

function GarageTender() {
  const { folderId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    id,
    T1,
    T2,
    T3,
    M1,
    M2,
    M3,
    P1,
    P2,
    ingOpaque,
    ingMetal,
    ingNacre,
    discountM,
    discountP,
    discountPart,
    discountI,
    discountInvoice,
  } = useSelector((state: TbaseReducer) => state.authReducer);

  const { tender, pathTender } = useSelector(
    (state: TbaseReducer) => state.garageReducer
  );

  const [parts, setParts] = useState<Array<Part>>([]);
  const [srgcs, setSrgcs] = useState<Array<SRGC>>([]);
  const [hours, setHours] = useState([]);
  const [discounts, setDiscounts] = useState({});

  const _getTender = useCallback(() => {
    if (folderId) {
      dispatch(getTender(parseInt(folderId)));
    }
  }, [folderId, dispatch]);

  useEffect(() => {
    _getTender();
  }, [folderId, _getTender]);

  const _setParts = useCallback(() => {
    const defaultData = {
      T1,
      T2,
      T3,
      M1,
      M2,
      M3,
      P1,
      P2,
      ingOpaque,
      ingMetal,
      ingNacre,
      discountM,
      discountP,
      discountPart,
      discountI,
      discountInvoice,
    };

    if (tender) {
      setParts(
        tender.parts.map((part) => ({
          ...part,
          price: part.price.replace(",", "."),
          discount: part.discount.replace(",", "."),
          dilapidated: part.dilapidated.replace(",", "."),
        }))
      );

      setSrgcs(
        tender.srgc.map((srgc) => ({
          ...srgc,
          total: srgc.total.replace(",", "."),
          discount: srgc.discount.replace(",", "."),
          dilapidated: srgc.dilapidated.replace(",", "."),
        }))
      );

      // @ts-ignore
      let tmpHours = [];

      let _hours: Array<keyof Hours> = [
        "T1",
        "T2",
        "T3",
        "M1",
        "M2",
        "M3",
        "P1",
        "P2",
        "ingOpaque",
        "ingMetal",
        "ingNacre",
      ];

      _hours.forEach((name) => {
        if (tender.hour.hasOwnProperty(name)) {
          const current = tender.hour[name];

          if (!current) {
            return null;
          }

          const hour = current.replace(",", ".");

          if (parseFloat(hour)) {
            tmpHours.push({
              name,
              hour,
              // @ts-ignore
              price: (defaultData[name] || "").toString(),
              discount: "",
            });
          }
        }
      });

      // @ts-ignore
      setHours(tmpHours);

      let _discounts: Array<keyof Hours> = [
        "discountM",
        "discountP",
        "discountPart",
        "discountI",
        "discountInvoice",
      ];

      // @ts-ignore
      let tmpDiscounts = {};

      _discounts.forEach((name) => {
        // @ts-ignore
        tmpDiscounts[name] = defaultData[name];
      });

      // @ts-ignore
      setDiscounts(tmpDiscounts);
    }
  }, [
    T1,
    T2,
    T3,
    M1,
    M2,
    M3,
    P1,
    P2,
    ingOpaque,
    ingMetal,
    ingNacre,
    discountM,
    discountP,
    discountPart,
    discountI,
    discountInvoice,
    tender,
  ]);

  useEffect(() => {
    _setParts();
  }, [tender, _setParts]);

  if (!tender) {
    return null;
  }

  interface TotalLineProps {
    title: string;
    start: number;
    name: string;
  }

  const TotalLine = ({ title, start, name }: TotalLineProps) => {
    return (
      <tr>
        <td>{title}</td>
        <td>{getCurrencyValue(start)}</td>
        <td>
          <Input
            type="number"
            // @ts-ignore
            value={discounts[name]}
            isPercentage
            onChange={(e) => {}}
          />
        </td>
        <td>
          {getCurrencyValue(
            getValue(
              start,
              // @ts-ignore
              [parseFloat(discounts[name])]
            )
          )}
        </td>
      </tr>
    );
  };

  return (
    <>
      <Row style={{ display: "flex", rowGap: 20 }}>
        <Col xs={12}>
          <Row style={{ display: "flex", rowGap: 20 }}>
            <Col xs={12} md={6}>
              <Card title="Identification" noHeight>
                <div className="d-flex flex-column" style={{ gap: 10 }}>
                  <span>
                    Assuré :{" "}
                    <b>
                      {tender.kind} {tender.name}
                    </b>
                  </span>
                  <span>
                    Téléphone : <b>{tender.phone}</b>
                  </span>
                  <span>
                    Référence : <b>{tender.reference}</b>
                  </span>
                  <span>
                    N° sinistre : <b>{tender.sinisterNumber}</b>
                  </span>
                </div>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card title="Véhicule" noHeight>
                <div className="d-flex flex-column" style={{ gap: 10 }}>
                  <span>
                    Marque : <b>{tender.brand}</b>
                  </span>
                  <span>
                    Modèle: <b>{tender.model}</b>
                  </span>
                  <span>
                    Immatriculation : <b>{tender.licensePlate}</b>
                  </span>
                  <span>
                    1MEC :{" "}
                    <b>
                      {moment(tender.dateRegistration).format("DD/MM/YYYY")}
                    </b>
                  </span>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Card title="Liste des pièces" noBodyPadding noHeight>
            <table className="table-tender">
              <thead>
                <tr>
                  <th>Qte.</th>
                  <th>Libellé</th>
                  <th>Op.</th>
                  <th>P.</th>
                  <th>Prix unitaire HT</th>
                  <th>% rem.</th>
                  <th>% vét.</th>
                  <th>Total HT</th>
                </tr>
              </thead>
              <tbody>
                {parts.map((part: Part, idx: number) => (
                  <tr key={idx}>
                    <td>{part.quantity}</td>
                    <td>{part.name}</td>
                    <td>{part.operation}</td>
                    <td>{part.paint}</td>
                    <td>
                      <Input
                        isCurrency
                        type={"number"}
                        value={part.price}
                        onChange={(e) => {
                          let tmp = JSON.parse(JSON.stringify(parts));
                          // @ts-ignore
                          tmparts[idx].price = e.target.value;
                          setParts(tmp);
                        }}
                      />
                    </td>
                    <td>
                      <Input
                        type={"number"}
                        value={part.discount}
                        onChange={(e) => {
                          let tmp = JSON.parse(JSON.stringify(parts));
                          // @ts-ignore
                          tmp[idx].discount = e.target.value;
                          setParts(tmp);
                        }}
                        isPercentage
                      />
                    </td>
                    <td>
                      {getPercentageValue(
                        parseFloat(
                          (part.dilapidated || "0").replace(",", ".")
                        ) / 100
                      )}
                    </td>
                    <td>
                      {getCurrencyValue(
                        getValue(parseFloat(part.price), [
                          parseFloat(part.discount),
                          parseFloat(part.dilapidated),
                        ])
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </Col>
        <Col xs={12}>
          <Card title="Liste des SRGC" noBodyPadding noHeight>
            <table className="table-tender">
              <thead>
                <tr>
                  <th>Libellé</th>
                  <th>Montant</th>
                  <th>% rem.</th>
                  <th>% vét.</th>
                  <th>Total HT</th>
                </tr>
              </thead>
              <tbody>
                {srgcs.map((srgc: SRGC, idx: number) => (
                  <tr key={idx}>
                    <td>{srgc.name}</td>
                    <td>
                      <Input
                        isCurrency
                        type="number"
                        value={srgc.total.replace(",", ".")}
                        onChange={(e) => {
                          let tmp = JSON.parse(JSON.stringify(srgcs));
                          // @ts-ignore
                          tmp[idx].total = e.target.value;
                          setSrgcs(tmp);
                        }}
                      />
                    </td>
                    <td>
                      <Input
                        type="number"
                        value={srgc.discount.replace(",", ".")}
                        isPercentage
                        onChange={(e) => {
                          let tmp = JSON.parse(JSON.stringify(srgcs));
                          // @ts-ignore
                          tmp[idx].discount = e.target.value;
                          setSrgcs(tmp);
                        }}
                      />
                    </td>
                    <td>
                      {getPercentageValue(
                        parseFloat(srgc.dilapidated.replace(",", ".")) / 100
                      )}
                    </td>
                    <td>
                      {getCurrencyValue(
                        getValue(parseFloat(srgc.total.replace(",", ".")), [
                          parseFloat(srgc.discount.replace(",", ".")),
                          parseFloat(srgc.dilapidated.replace(",", ".")),
                        ])
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </Col>
        <Col xs={12}>
          <Card title="Liste des heures" noBodyPadding noHeight>
            <table className="table-tender">
              <thead>
                <tr>
                  <th>Libellé</th>
                  <th>Heure(s)</th>
                  <th>Tarif horaire</th>
                  <th>% rem.</th>
                  <th>Total HT</th>
                </tr>
              </thead>
              <tbody>
                {hours.map((hour: Hour, idx: number) => (
                  <tr key={idx}>
                    <td>{hour.name}</td>
                    <td>{hour.hour}</td>
                    <td>
                      <Input
                        isCurrency
                        type="number"
                        value={hour.price}
                        onChange={(e) => {
                          let tmp = JSON.parse(JSON.stringify(hours));
                          // @ts-ignore
                          tmp[idx].price = e.target.value;
                          setHours(tmp);
                        }}
                      />
                    </td>
                    <td>
                      <Input
                        type="number"
                        value={hour.discount}
                        isPercentage
                        onChange={(e) => {
                          let tmp = JSON.parse(JSON.stringify(hours));
                          // @ts-ignore
                          tmp[idx].discount = e.target.value;
                          setHours(tmp);
                        }}
                      />
                    </td>
                    <td>
                      {getCurrencyValue(
                        getValue(
                          parseFloat(hour.hour.replace(",", ".")) *
                            parseFloat(hour.price.replace(",", ".")),
                          [parseFloat(hour.discount.replace(",", "."))]
                        )
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </Col>
        <Col xs={12}>
          <Card title="Totaux" noBodyPadding noHeight>
            <table className="table-tender">
              <thead>
                <tr>
                  <th>Libellé</th>
                  <th>Montant</th>
                  <th>% rem.</th>
                  <th>Total HT</th>
                </tr>
              </thead>
              <tbody>
                <TotalLine
                  title="Pièces"
                  start={getTotal(parts)}
                  name="discountPart"
                />
                <TotalLine
                  title="MO"
                  start={getTotal(hours, ["M1", "M2", "M3", "T1", "T2", "T3"])}
                  name="discountM"
                />
                <TotalLine
                  title="Ingrédients"
                  start={getTotal(hours, ["ingOpaque", "ingMetal", "ingNacre"])}
                  name="discountI"
                />
                <TotalLine
                  title="Peinture"
                  start={getTotal(hours, ["P1", "P2"])}
                  name="discountP"
                />
                <TotalLine
                  title="Total"
                  start={
                    // @ts-ignore
                    getValue(getTotal(parts), [discounts["discountPart"]]) +
                    getValue(
                      getTotal(hours, ["M1", "M2", "M3", "T1", "T2", "T3"]),
                      // @ts-ignore
                      [discounts["discountM"]]
                    ) +
                    getValue(
                      getTotal(hours, ["ingOpaque", "ingMetal", "ingNacre"]),
                      // @ts-ignore
                      [discounts["discountI"]]
                    ) +
                    getValue(
                      getTotal(hours, ["P1", "P2"]),
                      // @ts-ignore
                      [discounts["discountP"]]
                    )
                  }
                  name="discountInvoice"
                />
              </tbody>
            </table>
          </Card>
        </Col>
        <Col xs={12}>
          <Button
            onClick={async () => {
              if (id) {
                if (
                  window.confirm(
                    "Êtes-vous sûr de vouloir déposer votre offre ?"
                  )
                ) {
                  await dispatch(
                    insertTender(
                      parseInt(folderId || ""),
                      id,
                      parts,
                      srgcs,
                      hours,
                      discounts
                    )
                  );

                  await dispatch(getTenders(id));

                  alert("Votre offre a été déposée");

                  navigate("../");
                }
              }
            }}
            text="Déposer mon offre"
          />
        </Col>
      </Row>

      <ModalViewer
        path={pathTender}
        onClose={() =>
          dispatch({ type: GarageAction.SET_PATH_TENDER, body: { path: "" } })
        }
        title="Rapport photos"
      />
    </>
  );
}

export default GarageTender;
