import { LoaderAction } from "../reducers/loaderReducer";

import { AppDispatch } from "../store";

export const _fetch = (
  url: string,
  type: string,
  body: string,
  dispatch: AppDispatch,
  startLoader?: LoaderAction,
  endLoader?: LoaderAction
) => {
  dispatch({ type: startLoader || LoaderAction.START_LOAD });

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      dispatch({ type: endLoader || LoaderAction.STOP_LOAD });
      dispatch({ type, ...res, body: JSON.parse(body) });
    })
    .catch((err) => {
      dispatch({ type: endLoader || LoaderAction.STOP_LOAD });
    });
};
