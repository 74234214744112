import {
  Button,
  Icon,
  InputCheckbox,
  InputText,
} from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";
import moment from "moment";

import { useAppDispatch } from "../../reducers";

import { TbaseReducer } from "../../types/reducerType";

import { AuthAction, updateAvailabilities } from "../../actions/authAction";
import { GarageAction } from "../../actions/garageAction";

import { EmptyAppointment } from "../../types/garage";

interface ModalTableAppointmentsProps {
  onClose: Function;
}

export default function ModalTableAppointments({
  onClose,
}: ModalTableAppointmentsProps) {
  const dispatch = useAppDispatch();

  const { days, times, slot } = useSelector(
    (state: TbaseReducer) => state.authReducer
  );
  const { emptyAppointments } = useSelector(
    (state: TbaseReducer) => state.garageReducer
  );

  const addDay = (day: number) => {
    let tmpDays = JSON.parse(JSON.stringify(days));

    if (days.includes(day)) {
      tmpDays.splice(tmpDays.indexOf(day), 1);
    } else {
      tmpDays.push(day);
    }

    dispatch({
      type: AuthAction.UPDATE_DAYS,
      body: { days: tmpDays },
    });
  };

  const updateSlot = (slot: number) => {
    dispatch({
      type: AuthAction.UPDATE_SLOT,
      body: { slot },
    });
  };

  const addTime = () => {
    let tmpTimes = JSON.parse(JSON.stringify(times));

    tmpTimes.push({
      start: "",
      end: "",
    });

    dispatch({
      type: AuthAction.UPDATE_TIMES,
      body: { times: tmpTimes },
    });
  };

  const deleteTime = (idx: number) => {
    let tmpTimes = JSON.parse(JSON.stringify(times));

    tmpTimes.splice(idx, 1);

    dispatch({
      type: AuthAction.UPDATE_TIMES,
      body: { times: tmpTimes },
    });
  };

  const updateValueTime = (name: string, idx: number, value: string) => {
    let tmpTimes = JSON.parse(JSON.stringify(times));

    tmpTimes[idx][name] = value;

    dispatch({
      type: AuthAction.UPDATE_TIMES,
      body: { times: tmpTimes },
    });
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          display: "flex",
          left: 0,
          bottom: 0,
          right: 0,
          top: 0,
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 100,
        }}
      >
        <div
          style={{
            gap: 10,
            display: "flex",
            flexDirection: "column",
            width: 600,
            maxWidth: "100%",
            maxHeight: "100%",
            backgroundColor: "#fff",
            borderRadius: 10,
            padding: 20,
          }}
        >
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <h3>Modification des disponibilités</h3>
            <Icon icon={"Cross"} size={20} onClick={() => onClose()} />
          </div>
          <div>
            <h3>Jours actifs</h3>
          </div>
          <div className="d-flex" style={{ gap: 10 }}>
            <InputCheckbox
              text="Lundi"
              isCheck={days.includes(1)}
              onClick={() => addDay(1)}
            />
            <InputCheckbox
              text="Mardi"
              isCheck={days.includes(2)}
              onClick={() => addDay(2)}
            />
            <InputCheckbox
              text="Mercredi"
              isCheck={days.includes(3)}
              onClick={() => addDay(3)}
            />
            <InputCheckbox
              text="Jeudi"
              isCheck={days.includes(4)}
              onClick={() => addDay(4)}
            />
            <InputCheckbox
              text="Vendredi"
              isCheck={days.includes(5)}
              onClick={() => addDay(5)}
            />
            <InputCheckbox
              text="Samedi"
              isCheck={days.includes(6)}
              onClick={() => addDay(6)}
            />
          </div>
          <div>
            <h3>Plages horaires</h3>
          </div>
          <div className="d-flex flex-column" style={{ gap: 10 }}>
            {times.map((element: any, idx: number) => (
              <div key={idx} className="d-flex" style={{ gap: 10 }}>
                <InputText
                  type="time"
                  value={element.start || ""}
                  placeholder="Début"
                  onChange={(e) =>
                    updateValueTime("start", idx, e.target.value)
                  }
                />
                <InputText
                  type="time"
                  value={element.end || ""}
                  placeholder="Fin"
                  onChange={(e) => updateValueTime("end", idx, e.target.value)}
                />
                <Icon
                  icon="Trash"
                  size={16}
                  backgroundHover
                  onClick={() => deleteTime(idx)}
                />
              </div>
            ))}
            <Button
              style={{
                marginLeft: "auto",
              }}
              text="Ajouter une plage horaire"
              onClick={() => addTime()}
            />
          </div>
          <div>
            <h3>Tranches horaires</h3>
          </div>
          <div className="d-flex" style={{ gap: 10 }}>
            <Button
              text="20 minutes"
              outline={slot !== 20}
              onClick={() => updateSlot(20)}
            />
            <Button
              text="30 minutes"
              outline={slot !== 30}
              onClick={() => updateSlot(30)}
            />
            <Button
              text="60 minutes"
              outline={slot !== 60}
              onClick={() => updateSlot(60)}
            />
          </div>
          <div className="d-flex flex-column" style={{ gap: 10 }}>
            <Button
              style={{
                marginLeft: "auto",
              }}
              text="Valider les modifications"
              onClick={() => {
                const result = emptyAppointments.filter(
                  (element: EmptyAppointment) =>
                    element.status && moment(element.date).isAfter(moment())
                );

                if (result.length) {
                  alert(
                    "Il n'est pas possible de modifier les disponibilités si un RDV est planifié"
                  );
                } else {
                  dispatch(updateAvailabilities(days, times, slot));
                  dispatch({
                    type: GarageAction.GET_EMPTY_APPOINTMENTS,
                    body: { emptyAppointments: [] },
                  });
                  onClose();
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
