import { useState } from "react";
import Button from "./Button";

type DropdownProps = {
  title: string;
  active: string;
  white?: boolean;
  disabled?: boolean;
  handleSort?: Function;
};

function Dropdown({
  title,
  active,
  white,
  disabled,
  handleSort,
}: DropdownProps) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className="dropdown">
        <div className="button-picture-container">
          <button
            className={`button-picture`}
            style={{
              margin: "auto",
              ...(white ? { backgroundColor: "#fff" } : {}),
            }}
            onClick={handleOpen}
          >
            <span
              style={
                white
                  ? { backgroundColor: "#fff", color: "var(--primary-color)" }
                  : {}
              }
            >
              {title}
            </span>
          </button>
        </div>
        {open ? (
          <div className="dropdown-menu">
            <Button
              title="Prix"
              white={active === "price"}
              onClick={() => {
                handleOpen();
                if (handleSort) {
                  handleSort("price");
                }
              }}
            />
            <Button
              title="Distance"
              white={active === "distance"}
              onClick={() => {
                handleOpen();
                if (handleSort) {
                  handleSort("distance");
                }
              }}
            />
            <Button
              title="Responsabilité"
              white={active === "projectE"}
              onClick={() => {
                handleOpen();
                if (handleSort) {
                  handleSort("projectE");
                }
              }}
            />
            <Button
              title="Avis"
              white={active === "rank"}
              onClick={() => {
                handleOpen();
                if (handleSort) {
                  handleSort("rank");
                }
              }}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}

export default Dropdown;
