import { _fetch } from "../utils/fetch";

import { AppDispatch } from "../store";

export enum ClientAction {
  LOADER_APPOINTMENT = "LOADER_APPOINTMENT",
  SET_TOTAL_IMPORT = "SET_TOTAL_IMPORT",
  SET_GARAGE_ID = "SET_GARAGE_ID",
  START_GET_GARAGES = "START_GET_GARAGES",
  SET_LOCATION = "SET_LOCATION",
  CHECK_FOLDER = "CHECK_FOLDER",
  GET_FOLDER = "GET_FOLDER",
  ADD_FOLDER_FILE = "ADD_FOLDER_FILE",
  UPDATE_FOLDER_PICTURES = "UPDATE_FOLDER_PICTURES",
  GET_GARAGES = "GET_GARAGES",
  GET_GARAGE = "GET_GARAGE",
  GET_GARAGE_APPOINTMENTS = "GET_GARAGE_APPOINTMENTS",
  VALIDATE_APPOINTMENT = "VALIDATE_APPOINTMENT",
}

export const checkFolder = (token: string) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/client/check-folder",
      ClientAction.CHECK_FOLDER,
      JSON.stringify({
        token,
      }),
      dispatch
    );
  };
};

export const getFolder = (
  token: string,
  licensePlate: string,
  sinisterNumber: string
) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/client/get-folder",
      ClientAction.GET_FOLDER,
      JSON.stringify({
        token,
        licensePlate,
        sinisterNumber,
      }),
      dispatch
    );
  };
};

export const addFolderFile = (
  token: string,
  licensePlate: string,
  sinisterNumber: string,
  transfer: string,
  base64: string,
  name: string
) => {
  return async (dispatch: AppDispatch) => {
    return await _fetch(
      "/api/client/add-folder-file",
      ClientAction.ADD_FOLDER_FILE,
      JSON.stringify({
        token,
        licensePlate,
        sinisterNumber,
        transfer,
        base64,
        name,
      }),
      dispatch
    );
  };
};

export const updateFolderPictures = (
  token: string,
  licensePlate: string,
  sinisterNumber: string,
  otherData: any,
  transfer: string
) => {
  return (dispatch: AppDispatch) => {
    return _fetch(
      "/api/client/update-folder-pictures",
      ClientAction.UPDATE_FOLDER_PICTURES,
      JSON.stringify({
        token,
        licensePlate,
        sinisterNumber,
        otherData,
        transfer,
      }),
      dispatch
    );
  };
};

export const getGarages = (
  latitude: string,
  longitude: string,
  distance: number,
  token: string,
  licensePlate: string,
  sinisterNumber: string
) => {
  return (dispatch: AppDispatch) => {
    return _fetch(
      "/api/client/get-garages",
      ClientAction.GET_GARAGES,
      JSON.stringify({
        latitude,
        longitude,
        distance,
        token,
        licensePlate,
        sinisterNumber,
      }),
      dispatch
    );
  };
};

export const getGarage = (garageId: number) => {
  return (dispatch: AppDispatch) => {
    _fetch(
      "/api/client/get-garage",
      ClientAction.GET_GARAGE,
      JSON.stringify({ garageId }),
      dispatch
    );
  };
};

export const getGarageAppointments = (garageId: number) => {
  return (dispatch: AppDispatch) => {
    return _fetch(
      "/api/client/get-garage-appointments",
      ClientAction.GET_GARAGE_APPOINTMENTS,
      JSON.stringify({
        garageId,
      }),
      dispatch
    );
  };
};

export const validateAppointment = (
  token: string,
  licensePlate: string,
  sinisterNumber: string,
  garageId: number,
  date: string,
  garage: any
) => {
  return (dispatch: AppDispatch) => {
    return _fetch(
      "/api/client/validate-appointment",
      ClientAction.VALIDATE_APPOINTMENT,
      JSON.stringify({
        token,
        licensePlate,
        sinisterNumber,
        garageId,
        date,
        garage,
      }),
      dispatch
    );
  };
};
