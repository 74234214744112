import { combineReducers } from "redux";
import { useDispatch } from "react-redux";

import { clientReducer } from "./clientReducer";
import { garageReducer } from "./garageReducer";
import { loaderReducer } from "./loaderReducer";
import { authReducer } from "./authReducer";

import { AppDispatch } from "../store";

const config = {
  clientReducer,
  garageReducer,
  loaderReducer,
  authReducer,
};

const appReducer = combineReducers(config);

export const useAppDispatch: () => AppDispatch = useDispatch;

export default appReducer;
