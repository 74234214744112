import { useState } from "react";
import { Col } from "react-flexbox-grid";
import { Icon } from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";

import { TbaseReducer } from "../../types/reducerType";

import Card from "../../components/garage/Card";
import ModalServices from "./ModalServices";

interface LineProps {
  text: string;
}

function Services() {
  const { services } = useSelector((state: TbaseReducer) => state.authReducer);

  const [isShow, setIsShow] = useState(false);

  const Line = ({ text }: LineProps) => {
    return (
      <div
        className="d-flex"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#fff",
          boxShadow: "2px 4px 12px 0px rgba(80, 41, 41, 0.15)",
          padding: 10,
          borderRadius: 8,
        }}
      >
        <span style={{ fontSize: 14 }}>{text}</span>
      </div>
    );
  };

  return (
    <Col xs={12} md={12} lg={4}>
      <Card
        title="Nos services"
        rightHeader={
          <Icon size={18} icon={"Add"} onClick={() => setIsShow(true)} />
        }
        noHeight
      >
        <div className="d-flex flex-column" style={{ gap: 10 }}>
          {services.map((service: string, idx: number) => (
            <Line key={idx} text={service} />
          ))}
        </div>
      </Card>
      <ModalServices
        isShow={isShow}
        onClose={() => {
          setIsShow(false);
        }}
      />
    </Col>
  );
}

export default Services;
