type LoaderProps = {
  text: string;
  show: boolean;
  hide?: boolean;
  isAbsolute?: boolean;
};

function Loader({ text, show, hide, isAbsolute }: LoaderProps) {
  if (!show) {
    return <></>;
  }

  return (
    <div
      className={`button-signature-modal-container ${
        isAbsolute ? "button-signature-modal-container-absolute" : ""
      }`}
    >
      <div className="button-signature-modal">
        {hide ? null : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <span className="loader"></span>
          </div>
        )}
        <p>{text}</p>
      </div>
    </div>
  );
}

export default Loader;
