import { useState } from "react";
import { Icon } from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../reducers";

import { updateServices } from "../../actions/authAction";

import { TbaseReducer } from "../../types/reducerType";

import Input from "./Input";

interface ModalServicesProps {
  onClose: Function;
  isShow: boolean;
}

interface LineProps {
  text: string;
}

export default function ModalServices({ onClose, isShow }: ModalServicesProps) {
  const dispatch = useAppDispatch();

  const { services } = useSelector((state: TbaseReducer) => state.authReducer);

  const [customService, setCustomService] = useState("");

  if (!isShow) {
    return null;
  }

  const allServices = [
    "Facilité de règlement",
    "Lavage int/ext",
    "Service à domicile",
    "Véhicule de prêt",
    "Dépannage / remorquage",
    "Réparation / peinture carrosserie",
    "Réparation / entretien mécanique",
    "Réparation / diagnostic électronique",
  ];

  services.forEach((service) => {
    if (!allServices.includes(service)) {
      allServices.push(service);
    }
  });

  const _updateServices = (service: string) => {
    let tmpServices: Array<string> = JSON.parse(JSON.stringify(services));

    const idx = services.indexOf(service);

    if (idx >= 0) {
      tmpServices.splice(idx, 1);
    } else {
      tmpServices.push(service);
    }

    dispatch(updateServices(tmpServices));
  };

  const Line = ({ text }: LineProps) => {
    return (
      <div
        className="d-flex"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#fff",
          boxShadow: "2px 4px 12px 0px rgba(80, 41, 41, 0.15)",
          padding: 10,
          borderRadius: 8,
        }}
      >
        <span style={{ fontSize: 14 }}>{text}</span>
        <Icon
          icon={services.includes(text) ? "Trash" : "Plus"}
          size={18}
          color="var(--secondary-color)"
          onClick={() => _updateServices(text)}
        />
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          display: "flex",
          left: 0,
          bottom: 0,
          right: 0,
          top: 0,
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: 400,
            maxWidth: "100%",
            maxHeight: "100%",
            backgroundColor: "#fff",
            borderRadius: 10,
            padding: 20,
          }}
        >
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <h3>Modification des services</h3>
            <Icon icon={"Cross"} size={20} onClick={() => onClose()} />
          </div>
          <div className="d-flex flex-column" style={{ gap: 10 }}>
            {allServices.map((service: string, idx: number) => (
              <Line key={idx} text={service} />
            ))}
            <div className="d-flex" style={{ gap: 10 }}>
              <Input
                isFull
                value={customService}
                onChange={(e) => setCustomService(e.target.value)}
                placeholder="Service"
              />
              <Icon
                backgroundHover
                icon={"Plus"}
                size={18}
                color="var(--secondary-color)"
                disabled={!customService}
                onClick={() => _updateServices(customService)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
