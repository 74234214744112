import { useState, useRef, ChangeEvent, useEffect, useCallback } from "react";
// @ts-ignore
import { RCamera } from "react-camera-media-stream";

import check from "../../images/check.svg";
import trash from "../../images/trash.svg";
import sync from "../../images/sync.svg";
import { ResultBase64, getBase64 } from "../../utils/file";

type ButtonPictureMaskProps = {
  title: string;
  value: string | undefined;
  onChange: (base64: string) => void;
  required?: boolean;
  isFull?: boolean;
  mask: string;
};

interface ISomeObject {
  [key: string]: string;
}

function ButtonPictureMask({
  title,
  onChange,
  value,
  required,
  isFull,
  mask,
}: ButtonPictureMaskProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [currentImage, setCurrentImage] = useState<string | null>(value || "");
  const [isCamera, setIsCamera] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(0);

  const images: ISomeObject = {
    carAR: require("../../images/mask/carAR.png"),
    carAV: require("../../images/mask/carAV.png"),
    carAVG: require("../../images/mask/carAVG.png"),
    carAVD: require("../../images/mask/carAVD.png"),
    carARD: require("../../images/mask/carARD.png"),
    carARG: require("../../images/mask/carARG.png"),
    carLG: require("../../images/mask/carLG.png"),
    carLD: require("../../images/mask/carLD.png"),
  };

  let currentMask = images.hasOwnProperty(mask) ? images[mask] : "";

  const closeCamera = useCallback(() => {
    if (currentPosition) {
      setTimeout(() => {
        window.scrollTo(window.scrollX, currentPosition);
        setCurrentPosition(0);
      }, 100);
    }
  }, [currentPosition]);

  useEffect(() => {
    if (!isCamera) {
      closeCamera();
    }
  }, [isCamera, closeCamera]);

  return (
    <>
      <div className="button-picture-container">
        {currentImage ? (
          <>
            <div
              onClick={() => {
                setCurrentImage(null);
                onChange("");
              }}
            >
              <img className="button-picture-icon" alt="trash" src={trash} />
            </div>
            <div className="button-picture-viewer">
              <img src={currentImage} alt={title} />
            </div>
            <div onClick={() => inputRef.current?.click()}>
              <img
                className="button-picture-icon-right"
                alt="sync"
                src={sync}
              />
            </div>
          </>
        ) : null}
        <button
          className="button-document"
          style={{
            ...(isFull ? { width: "100%" } : {}),
            ...(currentImage ? { background: "var(--green)" } : {}),
          }}
          onClick={() => {
            setCurrentPosition(window.scrollY || 0);
            setIsCamera(true);
          }}
        >
          <span>
            {title} {required ? "(Obligatoire)" : "(Facultatif)"}
          </span>
          <img
            alt={mask}
            src={currentImage ? check : currentMask}
            height={24}
          />
        </button>
      </div>
      {isCamera ? (
        <RCamera
          model={currentMask}
          modelMargin={100}
          isConfirm={false}
          onTakePicture={(base64: string) => {
            setCurrentImage(base64);
            onChange(base64);
            setIsCamera(false);
          }}
          onClose={() => {
            setIsCamera(false);
          }}
          isFullscreen={true}
          namePicture=""
          isTorch={true}
        />
      ) : null}
      <input
        style={{ position: "absolute", top: -1000, left: -1000 }}
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.files) {
            let file = e.target.files[0];

            getBase64(file)
              .then((result: ResultBase64) => {
                setCurrentImage(result.base64);
                onChange(result.base64);
              })
              .catch(() => {});
          } else {
          }
        }}
      />
    </>
  );
}

export default ButtonPictureMask;
