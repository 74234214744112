import "./header.css";

import caretLeft from "../../../images/caret-left.svg";

type HeaderProps = {
  title: string;
  currentPage: number;
  totalPage: number;
  incrementPercentage?: number;
  onInfoClick?: Function;
  noBg?: boolean;
};

function Header({
  title,
  currentPage,
  totalPage,
  incrementPercentage,
  onInfoClick,
  noBg,
}: HeaderProps) {
  let currentPercentage =
    (currentPage * 100) / totalPage + (incrementPercentage || 0);

  return (
    <>
      <div
        className="header"
        style={
          noBg
            ? {
                backgroundColor: "inherit",
                boxShadow: "inherit",
              }
            : {}
        }
      >
        {onInfoClick ? (
          <div className="header-icon" onClick={() => onInfoClick()}>
            <img alt="caretLeft" src={caretLeft} />
          </div>
        ) : null}
        <h1 style={noBg ? { color: "#fff" } : {}}>
          {title}
          {noBg || incrementPercentage === undefined
            ? ""
            : ` - ${currentPercentage.toFixed(0)}%`}
        </h1>
        {!noBg && incrementPercentage !== undefined ? (
          <div className="progress">
            <div
              style={{
                right: `${100 - currentPercentage}%`,
              }}
            ></div>
          </div>
        ) : null}
      </div>
      <div
        className={noBg ? "header-rectifier-nobg" : "header-rectifier"}
      ></div>
    </>
  );
}

export default Header;
