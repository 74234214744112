import { useSelector } from "react-redux";
import { useEffect, useCallback } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";

import Timer from "../components/garage/Timer";
import Button from "../components/UI/Button";
import Loader from "../components/UI/Loader";

import { TbaseReducer } from "../types/reducerType";

import { useAppDispatch } from "../reducers";

import { getAppointments, GarageAction } from "../actions/garageAction";
import { signout } from "../actions/authAction";

import { ROUTE_API } from "../constants";

export default function GarageDesktop() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { countLoader } = useSelector(
    (state: TbaseReducer) => state.loaderReducer
  );
  const { isAuthenticated, name, id } = useSelector(
    (state: TbaseReducer) => state.authReducer
  );

  const { tender } = useSelector((state: TbaseReducer) => state.garageReducer);

  const _signout = () => {
    dispatch(signout());
  };

  const redirect = useCallback(() => {
    navigate("/garage/signin");
  }, [navigate]);

  useEffect(() => {
    if (!isAuthenticated) {
      redirect();
    }
  }, [isAuthenticated, redirect]);

  const _getAppointments = useCallback(() => {
    if (id) {
      dispatch(getAppointments(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    _getAppointments();
  }, [id, _getAppointments]);

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          backgroundColor: "#f9fdff",
        }}
      >
        <div
          className="d-flex flex-column"
          style={{
            width: 1200,
            padding: 100,
            margin: "auto",
            gap: 20,
          }}
        >
          <div
            className="d-flex"
            style={{
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              className="d-flex mt-auto"
              style={{ gap: 10, flexDirection: "column" }}
            >
              {location.pathname.includes("/garage/tender/") ? (
                <>
                  <h3 style={{ margin: 0 }}>Appel d'offres - AA-123-AA</h3>
                  <div className="d-flex" style={{ gap: 10 }}>
                    <Button
                      title="Accueil"
                      onClick={() => navigate("/garage")}
                    />
                    <Button
                      title="Visionner photos"
                      onClick={() => {
                        if (tender) {
                          dispatch({
                            type: GarageAction.SET_PATH_TENDER,
                            body: {
                              path: `${ROUTE_API}/garage/get-file/${
                                tender.fileId
                              }/${moment(tender.fileDate).format(
                                "YYYY-MM-DDTHH:mm:ss"
                              )}/pdf`,
                            },
                          });
                        }
                      }}
                    />
                  </div>
                </>
              ) : location.pathname === "/garage/tenders" ? (
                <>
                  <h3 style={{ margin: 0 }}>Historique offres</h3>
                  <div className="d-flex" style={{ gap: 10 }}>
                    <Button
                      title="Accueil"
                      onClick={() => navigate("/garage")}
                    />
                  </div>
                </>
              ) : location.pathname === "/garage/appointments" ? (
                <>
                  <h3 style={{ margin: 0 }}>Historique des RDV</h3>
                  <div className="d-flex" style={{ gap: 10 }}>
                    <Button
                      title="Accueil"
                      onClick={() => navigate("/garage")}
                    />
                  </div>
                </>
              ) : location.pathname === "/garage/account" ? (
                <>
                  <h3 style={{ margin: 0 }}>Mon compte</h3>
                  <div className="d-flex" style={{ gap: 10 }}>
                    <Button
                      title="Accueil"
                      onClick={() => navigate("/garage")}
                    />
                  </div>
                </>
              ) : (
                <>
                  <h3 style={{ margin: 0 }}>Bonjour {name} !</h3>
                  <div className="d-flex" style={{ gap: 10 }}>
                    <Button
                      title="Mon compte"
                      onClick={() => navigate("account")}
                      white
                    />
                    <Button
                      title="Historique RDV"
                      onClick={() => navigate("appointments")}
                      white
                    />
                    <Button
                      title="Historique offres"
                      onClick={() => navigate("tenders")}
                      white
                    />
                    <Button
                      title="Se déconnecter"
                      onClick={() => _signout()}
                      white
                    />
                  </div>
                </>
              )}
            </div>
            <Timer />
          </div>
          <Outlet />
          <div
            className="d-flex"
            style={{
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <a
              style={{ color: "var(--primary-color)", textDecoration: "none" }}
              href="/legals"
            >
              Mentions légales
            </a>
            <a
              style={{ color: "var(--primary-color)", textDecoration: "none" }}
              href="/uses-cases"
            >
              Conditions d'utilisations
            </a>
            <span style={{ color: "var(--secondary-color)" }}>
              Dashboard - ReseauCar
            </span>
          </div>
        </div>
      </div>
      <Loader show={countLoader > 0} text="Chargement en cours..." />
    </>
  );
}
