import moment from "moment";

import caretRight from "../../images/caret-right.png";

import { getCurrencyValue } from "../../utils/general";

import ProjectE from "./ProjectE";
import Star from "./Star";

import { useAppDispatch } from "../../reducers";
import { ClientAction } from "../../actions/clientAction";

import { ROUTE_API } from "../../constants";

interface GarageProps {
  id: number;
  name: string;
  price: number;
  distance: string;
  nextAppointment: string;
  projectE: number;
  rank: number;
}

function Garage({
  id,
  name,
  price,
  distance,
  nextAppointment,
  projectE,
  rank,
}: GarageProps) {
  const dispatch = useAppDispatch();

  return (
    <>
      <div
        style={{ display: "flex", gap: 8, flexShrink: 0, borderRadius: 10 }}
        className="cursor-pointer"
        onClick={() =>
          dispatch({ type: ClientAction.SET_GARAGE_ID, body: { garageId: id } })
        }
      >
        <div
          style={{
            position: "relative",
            height: 100,
            width: 100,
            borderRadius: 10,
            flexShrink: 0,
            overflow: "hidden",
          }}
        >
          <img
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            alt="presentation"
            src={`${ROUTE_API}/garage/auth/get-account-picture/${id}`}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: 4,
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <div style={{ display: "flex", gap: 8, overflow: "hidden" }}>
            <div
              style={{
                overflow: "hidden",
              }}
            >
              <h3
                style={{
                  margin: 0,
                  fontSize: 15,
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {name}
              </h3>
              <Star value={rank} />
            </div>
            <ProjectE value={projectE} />
          </div>
          <p
            style={{
              margin: 0,
              marginTop: "auto",
              color: "var(--primary-color)",
              fontWeight: "bold",
            }}
          >
            {getCurrencyValue(price)}
          </p>
          <p style={{ margin: 0 }}>{distance} km</p>
          <p style={{ margin: 0 }}>
            Prochain RDV : {moment(nextAppointment).format("DD/MM/YYYY")}
          </p>
        </div>
        <div
          style={{
            marginLeft: "auto",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <img alt="caret" height={32} src={caretRight} />
        </div>
      </div>
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          height: 2,
          width: "90%",
          backgroundColor: "#D9D9D9",
          flexShrink: 0,
        }}
      />
    </>
  );
}

export default Garage;
