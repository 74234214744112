import { useCallback, useEffect, useState } from "react";
import { Col } from "react-flexbox-grid";
import { Button } from "@sahadevia/ui-kit-modern-sahadevia";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

import { useAppDispatch } from "../../reducers";

import Card from "../../components/garage/Card";
import ModalViewer from "./ModalViewer";

import { getTenders } from "../../actions/garageAction";

import { ROUTE_API } from "../../constants";
import { TbaseReducer } from "../../types/reducerType";
import { Tenders as TendersType } from "../../types/garage";

function Tenders() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id } = useSelector((state: TbaseReducer) => state.authReducer);
  const { tenders } = useSelector((state: TbaseReducer) => state.garageReducer);

  const _getTenders = useCallback(() => {
    if (id) {
      dispatch(getTenders(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    _getTenders();
  }, [id, _getTenders]);

  const [currentPath, setCurrentPath] = useState("");

  return (
    <>
      <Col xs={12} md={12} lg={9}>
        <Card title={`Appels d'offres`} noBodyPadding>
          {tenders.length ? (
            <table className="table-tender">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Expiration</th>
                  <th>Réf</th>
                  <th>Marque</th>
                  <th>Modèle</th>
                  <th>Immatriculation</th>
                </tr>
              </thead>
              <tbody>
                {tenders.map((tender: TendersType, idx: number) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>
                      {moment(tender.date).add(10, "day").format("DD/MM/YYYY")}
                    </td>
                    <td>{tender.sinisterNumber}</td>
                    <td>{tender.brand}</td>
                    <td>{tender.model}</td>
                    <td>{tender.licensePlate}</td>
                    <th>
                      {tender.tenderId ? (
                        <Button
                          text="Visualiser mon offre"
                          onClick={() =>
                            setCurrentPath(
                              `${ROUTE_API}/garage/get-file/${tender.fileId}/${
                                tender.fileDate.split("T")[0]
                              }/pdf`
                            )
                          }
                        />
                      ) : (
                        <Button
                          text="Faire une offre"
                          onClick={() => navigate(`tender/${tender.id}`)}
                        />
                      )}
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <span>Aucun appel d'offres disponible</span>
            </div>
          )}
        </Card>
      </Col>
      <ModalViewer
        path={currentPath}
        onClose={() => setCurrentPath("")}
        title="Appel d'offres"
      />
    </>
  );
}

export default Tenders;
