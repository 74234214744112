import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Header from "../../components/UI/header/Header";
import Paragraph from "../../components/UI/Paragraph";
import InputText from "../../components/UI/InputText";
import Menu from "../../components/UI/menu/Menu";
import ButtonPictureMask from "../../components/UI/ButtonPictureMask";
import ButtonInfo from "../../components/UI/ButtonInfo";
import ButtonPicture from "../../components/UI/ButtonPicture";
import Checkbox from "../../components/UI/Checkbox";
import ButtonSignature from "../../components/UI/ButtonSignature";
import Loader from "../../components/UI/Loader";

import { useAppDispatch } from "../../reducers";

import {
  ClientAction,
  addFolderFile,
  updateFolderPictures,
} from "../../actions/clientAction";

import logo from "../../images/logo-bleu.png";
import { TbaseReducer } from "../../types/reducerType";

type ButtonPictureType = {
  title: string;
  value: string;
  isCheck?: boolean;
  required?: boolean;
  isFile?: boolean;
  mask?: string;
};

type DataPicturesType = {
  [key: string]: ButtonPictureType;
};

function ClientPicture() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { token } = useParams();

  let { licensePlate, sinisterNumber, isUpload, totalImport, countImport } =
    useSelector((state: TbaseReducer) => state.clientReducer);

  useEffect(() => {
    if (isUpload && totalImport === countImport) {
      navigate("/pictures-success");
    }
  }, [countImport, isUpload, totalImport, navigate]);

  const [currentPage, setCurrentPage] = useState(0);
  const totalPage = 3;
  const [dataPictures, setDataPictures] = useState<DataPicturesType>({
    p1: {
      title: "Certificat d’immatriculation",
      value: "",
      required: true,
      isFile: true,
    },
    p2: {
      title: "Compteur kilométrique",
      value: "",
      required: true,
      isFile: true,
    },
    p3: {
      title: "Numéro de série",
      value: "",
      required: true,
      isFile: true,
    },
    p4: {
      title: "Vignette CT / assurance",
      value: "",
      required: true,
      isFile: true,
    },
    p5: {
      title: "Plaque immatriculation",
      value: "",
      required: true,
      isFile: true,
    },
    p6: {
      title: "3/4 avant gauche",
      value: "",
      required: true,
      mask: "carAVG",
      isFile: true,
    },
    p7: {
      title: "3/4 avant droit",
      value: "",
      required: true,
      mask: "carAVD",
      isFile: true,
    },
    p8: {
      title: "3/4 arrière gauche",
      value: "",
      required: true,
      mask: "carARD",
      isFile: true,
    },
    p9: {
      title: "3/4 arrière droit",
      value: "",
      required: true,
      mask: "carARG",
      isFile: true,
    },
    p10: {
      title: "Dommages à 3 mètres",
      value: "",
      required: true,
      isFile: true,
    },
    p11: {
      title: "Dommages à 1 mètre",
      value: "",
      required: true,
      isFile: true,
    },
    p12: {
      title: "Dommages vue de gauche",
      value: "",
      required: true,
      isFile: true,
    },
    p13: {
      title: "Dommages vue de droite",
      value: "",
      required: true,
      isFile: true,
    },
    p14: {
      title: "Autre photo des dommages 1",
      value: "",
      isFile: true,
    },
    p15: {
      title: "Autre photo des dommages 2",
      value: "",
      isFile: true,
    },
    p16: {
      title: "Autre photo des dommages 3",
      value: "",
      isFile: true,
    },
    p17: {
      title: "Autre photo des dommages 4",
      value: "",
      isFile: true,
    },
    p18: {
      title: "Autre photo des dommages 5",
      value: "",
      isFile: true,
    },
    c19: {
      title:
        "Je suis sensible aux enjeux environnementaux et je vous donne mon accord pour l’utilisation de la pièce de réemploi selon disponibilité.",
      value: "",
      isCheck: false,
    },
    c20: {
      title:
        "Je souhaite envoyer les photos de mon véhicule au cabinet d'expertise dans le cadre de la gestion de mon sinistre.",
      value: "",
      isCheck: false,
      required: true,
    },
    t21: {
      title: "Nom et Prénom",
      value: "",
      required: true,
    },
    s22: {
      title: "Signature",
      value: "",
      required: true,
      isFile: true,
    },
  });

  const getTitle = () => {
    if (currentPage === 0) {
      return "Photos d'identification";
    } else if (currentPage === 1) {
      return "Photos des dommages";
    } else if (currentPage === 2) {
      return "Signature et envoi";
    } else {
      return "";
    }
  };

  const updateDataPicturesBase64 = (name: string, base64: string) => {
    setDataPictures({
      ...dataPictures,
      [name]: {
        ...dataPictures[name],
        value: base64,
      },
    });
  };

  const getButtonPicture = (
    { title, value, required }: ButtonPictureType,
    key: string
  ) => {
    return (
      <ButtonPicture
        isFull
        title={title}
        value={value}
        onChange={(base64) => updateDataPicturesBase64(key, base64)}
        required={required}
      />
    );
  };

  const getButtonPictureMask = (
    { title, value, required, mask }: ButtonPictureType,
    key: string
  ) => {
    return (
      <ButtonPictureMask
        isFull
        title={title}
        value={value}
        onChange={(base64) => updateDataPicturesBase64(key, base64)}
        required={required}
        mask={mask || ""}
      />
    );
  };

  const checkData = (names: Array<string>) => {
    let isDisabled = false;

    names.forEach((name) => {
      if (!dataPictures[name].value) {
        isDisabled = true;
      }
    });

    return isDisabled;
  };

  return (
    <>
      <Header
        title={getTitle()}
        currentPage={currentPage}
        totalPage={totalPage}
        incrementPercentage={0}
      />

      {currentPage === 0 ? (
        <>
          <img
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              mixBlendMode: "multiply",
            }}
            height={120}
            alt="ReseauCar"
            src={logo}
          />

          <Paragraph
            text="Nous vous invitons à prendre en photo votre véhicule"
            reference={"00000"}
            variables={[]}
          />
          {getButtonPicture(dataPictures.p1, "p1")}
          {getButtonPicture(dataPictures.p2, "p2")}
          <ButtonInfo title="Où trouver le numéro de série ?" />
          {getButtonPicture(dataPictures.p3, "p3")}
          {getButtonPicture(dataPictures.p4, "p4")}
          {getButtonPicture(dataPictures.p5, "p5")}
          {getButtonPictureMask(dataPictures.p6, "p6")}
          {getButtonPictureMask(dataPictures.p7, "p7")}
          {getButtonPictureMask(dataPictures.p8, "p8")}
          {getButtonPictureMask(dataPictures.p9, "p9")}
        </>
      ) : null}

      {currentPage === 1 ? (
        <>
          {getButtonPicture(dataPictures.p10, "p10")}
          {getButtonPicture(dataPictures.p11, "p11")}
          {getButtonPicture(dataPictures.p12, "p12")}
          {getButtonPicture(dataPictures.p13, "p13")}
          {getButtonPicture(dataPictures.p14, "p14")}
          {getButtonPicture(dataPictures.p15, "p15")}
          {getButtonPicture(dataPictures.p16, "p16")}
          {getButtonPicture(dataPictures.p17, "p17")}
          {getButtonPicture(dataPictures.p18, "p18")}
        </>
      ) : null}

      {currentPage === 2 ? (
        <>
          <Checkbox
            checked={dataPictures.c19.isCheck || false}
            onClick={() =>
              setDataPictures({
                ...dataPictures,
                c19: {
                  ...dataPictures.c19,
                  isCheck: !dataPictures.c19.isCheck,
                },
              })
            }
            text={dataPictures.c19.title}
            required={dataPictures.c19.required}
          />
          <Checkbox
            checked={dataPictures.c20.isCheck || false}
            onClick={() =>
              setDataPictures({
                ...dataPictures,
                c20: {
                  ...dataPictures.c20,
                  isCheck: !dataPictures.c20.isCheck,
                },
              })
            }
            text={dataPictures.c20.title}
            required={dataPictures.c20.required}
          />
          <InputText
            title={dataPictures.t21.title}
            placeholder={""}
            value={dataPictures.t21.value}
            onChange={(value) => updateDataPicturesBase64("t21", value)}
            required={dataPictures.t21.required}
          />
          <ButtonSignature
            required={dataPictures.s22.required}
            value={dataPictures.s22.value}
            onChange={(value) => updateDataPicturesBase64("s22", value)}
          />
        </>
      ) : null}

      <Menu
        currentPage={currentPage}
        totalPage={totalPage}
        disabled={
          (currentPage === 0 &&
            checkData([
              "p1",
              "p2",
              "p3",
              "p4",
              "p5",
              "p6",
              "p7",
              "p8",
              "p9",
            ])) ||
          (currentPage === 1 && checkData(["p10", "p11", "p12", "p13"])) ||
          (currentPage === 2 &&
            (!dataPictures.c20.isCheck || checkData(["t21", "s22"])))
        }
        onPageChange={(page: number) => {
          if (page < 0) {
          } else {
            setCurrentPage(page);
          }
        }}
        onEnd={async () => {
          const transfer = crypto.randomUUID();
          const keysFile = Object.keys(dataPictures).filter(
            (key) => dataPictures[key].isFile && dataPictures[key].value
          );
          const keysOther = Object.keys(dataPictures).filter(
            (key) => !dataPictures[key].isFile
          );

          dispatch({
            type: ClientAction.SET_TOTAL_IMPORT,
            body: { totalImport: keysFile.length },
          });

          for (let index = 0; index < keysFile.length; index++) {
            const key = keysFile[index];

            await dispatch(
              addFolderFile(
                token || "",
                licensePlate,
                sinisterNumber,
                transfer,
                dataPictures[key].value,
                dataPictures[key].title
              )
            );
          }

          const othersData = keysOther.map((key) => ({
            key,
            title: dataPictures[key].title,
            isCheck:
              dataPictures[key].isCheck !== undefined
                ? dataPictures[key].isCheck
                : undefined,
            value: dataPictures[key].value || undefined,
          }));

          dispatch(
            updateFolderPictures(
              token || "",
              licensePlate,
              sinisterNumber,
              othersData,
              transfer
            )
          );
        }}
      />
      <Loader
        show={isUpload}
        text={`${((countImport / totalImport) * 100).toFixed(0)}%`}
      />
    </>
  );
}

export default ClientPicture;
