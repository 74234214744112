import React from "react";

interface CardProps {
  title: string;
  children: JSX.Element;
  rightHeader?: JSX.Element;
  style?: React.CSSProperties;
  noBodyPadding?: boolean;
  noHeight?: boolean;
}

export default function Card({
  title,
  children,
  rightHeader,
  style,
  noBodyPadding,
  noHeight,
}: CardProps) {
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#fff",
        boxShadow: "2px 4px 12px 0px rgba(80, 41, 41, 0.15)",
        borderRadius: 8,
        minHeight: noHeight ? "inherit" : 360,
        maxHeight: noHeight ? "inherit" : 360,
        height: "100%",
        flexDirection: "column",
        gap: 10,
        ...style,
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          padding: 20,
          paddingBottom: 0,
        }}
      >
        <h3 className="m-0" style={{ fontWeight: "normal" }}>
          {title}
        </h3>
        <div className="d-flex">{rightHeader}</div>
      </div>
      <div
        className="d-flex flex-column"
        style={{
          height: "100%",
          width: "100%",
          padding: noBodyPadding ? 0 : 20,
          paddingTop: 0,
          overflowY: "auto",
        }}
      >
        {children}
      </div>
    </div>
  );
}
