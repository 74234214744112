import { useSelector } from "react-redux";

import { TbaseReducer } from "../../types/reducerType";

import { useAppDispatch } from "../../reducers";

import { AuthAction } from "../../actions/authAction";
import { getPercentageValue } from "../../utils/general";

interface InputPriceProps {
  title: string;
  name: string;
  isPercentage?: boolean;
  isAround?: boolean;
}

const InputPrice = ({
  title,
  isPercentage,
  name,
  isAround,
}: InputPriceProps) => {
  const dispatch = useAppDispatch();

  const authReducer = useSelector((state: TbaseReducer) => state.authReducer);

  const { aroundPrices } = useSelector(
    (state: TbaseReducer) => state.garageReducer
  );

  return (
    <div
      style={{
        display: "inline-flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 8,
      }}
    >
      <span>{title}</span>
      <div
        style={{
          display: "inline-flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: "solid #0095ae 1px",
          borderRadius: 6,
          padding: 8,
        }}
      >
        <input
          placeholder="00"
          style={{
            color: "#3597D3",
            border: "none",
            width: "100%",
          }}
          // @ts-ignore
          value={authReducer[name] || ""}
          onChange={(e) => {
            dispatch({
              type: AuthAction.UPDATE_DATA,
              body: {
                name,
                value: e.target.value,
              },
            });
          }}
        />
        <span>{isPercentage ? "%" : "€"} </span>
      </div>
      {isAround ? (
        <span
          style={{
            // @ts-ignore
            color: (aroundPrices[name] || 0) / parseFloat(authReducer[name]) > 1 ? "var(--green)" : "var(--red)",
            fontSize: 14,
          }}
        >
          {getPercentageValue(
            // @ts-ignore
            1 - (aroundPrices[name] || 0) / parseFloat(authReducer[name]) || 0
          )}*
        </span>
      ) : null}
    </div>
  );
};

export default InputPrice;
