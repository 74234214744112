import { useRef, ChangeEvent, useState } from "react";

import { ResultBase64, getBase64 } from "../../utils/file";

import check from "../../images/check.svg";
import trash from "../../images/trash.svg";
import camera from "../../images/camera.svg";
import sync from "../../images/sync.svg";
import pdf from "../../images/pdf.png";

type ButtonPictureProps = {
  title: string;
  value: string | undefined;
  onChange: (base64: string) => void;
  required?: boolean;
  isFull?: boolean;
};

function ButtonPicture({
  title,
  onChange,
  value,
  required,
  isFull,
}: ButtonPictureProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [currentImage, setCurrentImage] = useState<string | null>(value || "");

  return (
    <div className="button-picture-container">
      {currentImage ? (
        <>
          <div
            onClick={() => {
              setCurrentImage(null);
              onChange("");
            }}
          >
            <img className="button-picture-icon" alt="trash" src={trash} />
          </div>
          <div className="button-picture-viewer">
            <img
              height={
                currentImage.includes("image/png") ||
                currentImage.includes("image/jpg") ||
                currentImage.includes("image/jpeg")
                  ? ""
                  : "36"
              }
              src={
                currentImage.includes("image/png") ||
                currentImage.includes("image/jpg") ||
                currentImage.includes("image/jpeg")
                  ? currentImage
                  : pdf
              }
              alt={title}
            />
          </div>
          <div onClick={() => inputRef.current?.click()}>
            <img className="button-picture-icon-right" alt="sync" src={sync} />
          </div>
        </>
      ) : null}
      <button
        className="button-picture"
        style={{
          ...(isFull ? { width: "100%" } : {}),
          ...(currentImage ? { background: "var(--green)" } : {}),
        }}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        <span>
          {title} {required ? "(Obligatoire)" : "(Facultatif)"}
        </span>
        <img alt="camera" src={currentImage ? check : camera} />
      </button>
      <input
        style={{ position: "absolute", top: -1000, left: -1000 }}
        ref={inputRef}
        type="file"
        accept="image/*"
        capture="environment"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.files) {
            let file = e.target.files[0];

            getBase64(file)
              .then((result: ResultBase64) => {
                setCurrentImage(result.base64);
                onChange(result.base64);
              })
              .catch(() => {});
          } else {
          }
        }}
      />
    </div>
  );
}

export default ButtonPicture;
