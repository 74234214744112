import { Icon } from "@sahadevia/ui-kit-modern-sahadevia";

interface ModalViewerProps {
  onClose: Function;
  path: string;
  title: string;
}

export default function ModalViewer({
  onClose,
  path,
  title,
}: ModalViewerProps) {
  if (!path) {
    return null;
  }

  return (
    <>
      <div
        style={{
          position: "fixed",
          display: "flex",
          left: 0,
          bottom: 0,
          right: 0,
          top: 0,
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: 900,
            height: 1000,
            maxWidth: "100%",
            maxHeight: "100%",
            backgroundColor: "#fff",
            borderRadius: 10,
            padding: 20,
          }}
        >
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <h3>{title}</h3>
            <Icon icon={"Cross"} size={20} onClick={() => onClose()} />
          </div>

          <iframe
            title={title}
            style={{
              width: "100%",
              height: "100%",
            }}
            src={path}
          />
        </div>
      </div>
    </>
  );
}
