import "./menu.css";

type MenuProps = {
  currentPage: number;
  totalPage: number;
  onPageChange: Function;
  onEnd: Function;
  disabled: boolean;
};

function Menu({
  currentPage,
  totalPage,
  onPageChange,
  onEnd,
  disabled,
}: MenuProps) {
  return (
    <>
      <div style={{ width: "100%", height: "56px", flexShrink: 0 }}></div>
      <div className="menu">
        {currentPage ? (
          <button
            style={{ width: "40%" }}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              onPageChange(currentPage - 1);
            }}
          >
            <span>Retour</span>
          </button>
        ) : null}
        <button
          className={disabled ? "menu-button-disabled" : ""}
          onClick={() => {
            if (disabled) {
              alert("Veuillez remplir tous les champs obligatoires");
            } else {
              if (currentPage + 1 === totalPage) {
                onEnd();
              } else {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                onPageChange(currentPage + 1);
              }
            }
          }}
        >
          <span>{currentPage + 1 === totalPage ? "Envoyer" : "Suivant"}</span>
        </button>
      </div>
    </>
  );
}

export default Menu;
