import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../reducers";

import { checkFolder, getFolder } from "../../actions/clientAction";

import InputText from "../../components/UI/InputText";
import Button from "../../components/UI/Button";
import { TbaseReducer } from "../../types/reducerType";

function ClientAuth() {
  const dispatch = useAppDispatch();

  const { token } = useParams();

  let { isNoExist, isError, isAuth } = useSelector(
    (state: TbaseReducer) => state.clientReducer
  );

  const [licensePlate, setLicensePlate] = useState("");
  const [sinisterNumber, setSinisterNumber] = useState("");

  useEffect(() => {
    dispatch(checkFolder(token || ""));
  }, [dispatch, token]);

  useEffect(() => {
    if (!isAuth) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isAuth]);

  if (isNoExist) {
    return (
      <div
        className="backdrop-filter-blur"
        style={{
          zIndex: 100,
          display: "flex",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          padding: 20,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 20,
            left: 20,
            marginLeft: "auto",
          }}
        >
          <Button
            title="Besoin d'aide ?"
            onClick={() => {
              window.location.href = "tel:+33623761142";
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: 600,
            margin: "auto",
            padding: 20,
            backgroundColor: "#fff",
            borderRadius: 5,
            boxShadow: "0px -2px 14px 0px rgba(69, 27, 27, 0.15)",
            gap: 20,
          }}
        >
          <h2
            style={{
              margin: 0,
              textAlign: "center",
              color: "var(--primary-color)",
            }}
          >
            Dossier en cours de traitement
          </h2>
          <p>
            Le lien de connexion n’est plus disponible car les informations ont
            déjà été transmises, nous reviendrons vers vous rapidement.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      {!isAuth ? (
        <div
          className="backdrop-filter-blur"
          style={{
            zIndex: 100,
            display: "flex",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            padding: 20,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 20,
              left: 20,
              marginLeft: "auto",
            }}
          >
            <Button
              title="Besoin d'aide ?"
              onClick={() => {
                window.location.href = "tel:+33623761142";
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              maxWidth: 600,
              margin: "auto",
              padding: 20,
              backgroundColor: "#fff",
              borderRadius: 5,
              boxShadow: "0px -2px 14px 0px rgba(69, 27, 27, 0.15)",
              gap: 20,
            }}
          >
            <h2
              style={{
                margin: 0,
                textAlign: "center",
                color: "var(--primary-color)",
              }}
            >
              Authentification
            </h2>
            <InputText
              title={"Immatriculation"}
              placeholder={"AA-123-AA"}
              value={licensePlate}
              onChange={(value) => setLicensePlate(value)}
              required={true}
            />
            <InputText
              title={"N° de sinistre"}
              placeholder={"123456789A"}
              value={sinisterNumber}
              onChange={(value) => setSinisterNumber(value)}
              required={true}
            />
            {isError ? (
              <p style={{ color: "var(--red)" }}>
                Les informations entrées sont incomplètes ou éronnées, veuillez
                les vérifier et réessayer.
              </p>
            ) : null}
            <div style={{ display: "flex", margin: "auto" }}>
              <Button
                title="Valider"
                disabled={!licensePlate || !sinisterNumber}
                onClick={() => {
                  dispatch(
                    getFolder(token || "", licensePlate, sinisterNumber)
                  );
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
      <Outlet />
    </>
  );
}

export default ClientAuth;
