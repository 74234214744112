import { Col } from "react-flexbox-grid";
import { useSelector } from "react-redux";
import { Button } from "@sahadevia/ui-kit-modern-sahadevia";

import { TbaseReducer } from "../../types/reducerType";

import Card from "../../components/garage/Card";
import InputPrice from "./InputPrice";

import { useAppDispatch } from "../../reducers";

import { updatePrices } from "../../actions/authAction";

import { useCallback, useEffect } from "react";
import { getPricesAround } from "../../actions/garageAction";
import Loader from "../UI/Loader";

function Prices() {
  const dispatch = useAppDispatch();

  const {
    id,
    isUpdatePrices,
    T1,
    T2,
    T3,
    M1,
    M2,
    M3,
    P1,
    P2,
    ingOpaque,
    ingMetal,
    ingNacre,
    discountM,
    discountP,
    discountPart,
    discountI,
    discountInvoice,
  } = useSelector((state: TbaseReducer) => state.authReducer);

  const { isLoadPrices } = useSelector(
    (state: TbaseReducer) => state.loaderReducer
  );

  const _updatePrices = () => {
    dispatch(
      updatePrices({
        T1,
        T2,
        T3,
        M1,
        M2,
        M3,
        P1,
        P2,
        ingOpaque,
        ingMetal,
        ingNacre,
        discountM,
        discountP,
        discountPart,
        discountI,
        discountInvoice,
      })
    );
  };

  const _getPricesAround = useCallback(() => {
    if (id) {
      dispatch(getPricesAround(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    _getPricesAround();
  }, [id, _getPricesAround]);

  return (
    <Col xs={12} md={12} lg={8}>
      <Card
        title="Gestion des tarifs"
        noHeight
        style={{ position: "relative" }}
      >
        <>
          <Loader show={isLoadPrices} text="" isAbsolute />
          <div>
            <span style={{ fontSize: 14 }}>
              * différence avec le taux moyen des réparateurs dans un rayon de
              10km
            </span>
            <div
              className="d-flex"
              style={{
                gap: 10,
                paddingTop: 12,
                paddingBottom: 12,
                borderBottom: "solid 1px rgba(53, 151, 211, 0.4)",
              }}
            >
              <InputPrice title="M1" name="M1" isAround />
              <InputPrice title="M2" name="M2" isAround />
              <InputPrice title="M3" name="M3" isAround />
            </div>
            <div
              className="d-flex"
              style={{
                gap: 10,
                paddingTop: 12,
                paddingBottom: 12,
                borderBottom: "solid 1px rgba(53, 151, 211, 0.4)",
              }}
            >
              <InputPrice title="T1" name="T1" isAround />
              <InputPrice title="T2" name="T2" isAround />
              <InputPrice title="T3" name="T3" isAround />
            </div>
            <div
              className="d-flex"
              style={{
                gap: 10,
                paddingTop: 12,
                paddingBottom: 12,
                borderBottom: "solid 1px rgba(53, 151, 211, 0.4)",
              }}
            >
              <InputPrice title="IO" name="ingOpaque" isAround />
              <InputPrice title="IM" name="ingMetal" isAround />
              <InputPrice title="IN" name="ingNacre" isAround />
            </div>
            <div
              className="d-flex"
              style={{
                gap: 10,
                paddingTop: 12,
                paddingBottom: 12,
                borderBottom: "solid 1px rgba(53, 151, 211, 0.4)",
              }}
            >
              <InputPrice title="P1" name="P1" isAround />
              <InputPrice title="P2" name="P2" isAround />
            </div>
            <div
              className="d-flex flex-column"
              style={{
                paddingTop: 12,
                gap: 10,
              }}
            >
              <span>Remises</span>
              <div
                className="d-flex"
                style={{
                  gap: 10,
                  paddingBottom: 12,
                }}
              >
                <InputPrice title="M.O" name="discountM" isPercentage />
                <InputPrice title="Peinture" name="discountP" isPercentage />
                <InputPrice title="Ingrédient" name="discountI" isPercentage />
              </div>
              <div
                className="d-flex"
                style={{
                  gap: 10,
                  paddingTop: 12,
                  paddingBottom: 12,
                }}
              >
                <InputPrice title="Pièces" name="discountPart" isPercentage />
                <InputPrice
                  title="Facture"
                  name="discountInvoice"
                  isPercentage
                />
              </div>
            </div>

            <div
              className="d-flex"
              style={{
                justifyContent: "end",
              }}
            >
              <Button
                disabled={!isUpdatePrices}
                text="Enregistrer"
                onClick={() => _updatePrices()}
              />
            </div>
          </div>
        </>
      </Card>
    </Col>
  );
}

export default Prices;
