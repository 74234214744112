import { Fragment, useState, useCallback, useEffect } from "react";
import moment from "moment";
import { Col } from "react-flexbox-grid";
import { Icon } from "@sahadevia/ui-kit-modern-sahadevia";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../reducers";

import Card from "../../components/garage/Card";
import ModalTableAppointments from "./ModalTableAppointments";
import ModalAppointment from "./ModalAppointment";

import { TbaseReducer } from "../../types/reducerType";

import { EmptyAppointment } from "../../types/garage";

import {
  getEmptyAppointments,
  insertEmptyAppointment,
  deleteEmptyAppointment,
} from "../../actions/garageAction";

const holidays = [
  "2024-01-01",
  "2024-04-01",
  "2024-05-01",
  "2024-05-08",
  "2024-05-09",
  "2024-05-20",
  "2024-07-14",
  "2024-08-15",
  "2024-11-01",
  "2024-11-11",
  "2024-12-25",
  "2025-01-01",
  "2025-04-21",
  "2025-05-01",
  "2025-05-08",
  "2025-05-29",
  "2025-06-08",
  "2025-07-14",
  "2025-08-15",
  "2025-11-01",
  "2025-11-11",
  "2025-12-25",
];

function TableAppointments() {
  const dispatch = useAppDispatch();

  const {
    id,
    days: daysOpen,
    times,
    slot,
  } = useSelector((state: TbaseReducer) => state.authReducer);
  const { emptyAppointments } = useSelector(
    (state: TbaseReducer) => state.garageReducer
  );

  const _getEmptyAppointments = useCallback(() => {
    if (id) {
      dispatch(getEmptyAppointments(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    _getEmptyAppointments();
  }, [id, _getEmptyAppointments]);

  const _insertEmptyAppointments = (dates: Array<string>) => {
    dates.forEach((date: string) => {
      dispatch(insertEmptyAppointment(date));
    });
  };

  const [currentWeek, setCurrentWeek] = useState(0);
  const [isModalAppointment, setIsModalAppointment] = useState(-1);
  const [isModalTableAppointments, setIsModalTableAppointments] =
    useState(false);

  const getCurrentWeek = () => {
    return moment().add(currentWeek, "week").startOf("week");
  };

  let dates: {
    [key: string]: {
      dates: Array<EmptyAppointment>;
      items: Array<{
        start: string;
        end: string;
        status: number;
      }>;
    };
  } = {};
  let countAvailable = 0;

  emptyAppointments.forEach((element: EmptyAppointment) => {
    if (element.status === 0) {
      countAvailable++;
    }

    const date = moment(element.date).format("YYYY-MM-DD");
    if (!dates.hasOwnProperty(date)) {
      dates[date] = {
        dates: [],
        items: [],
      };
    }
    dates[date].dates.push(element);
  });

  Object.keys(dates).forEach((date) => {
    let start = "";

    dates[date].dates.forEach((subDate, subIdx: number) => {
      if (!start) {
        start = subDate.date;
      }

      if (
        dates[date].dates.length === subIdx + 1 ||
        !moment(subDate.date)
          .add(30, "minute")
          .isSame(moment(dates[date].dates[subIdx + 1].date)) ||
        subDate.status !== dates[date].dates[subIdx + 1].status
      ) {
        dates[date].items.push({
          start,
          end: moment(subDate.date)
            .add(30, "minute")
            .format("YYYY-MM-DD HH:mm:ss"),
          status: subDate.status,
        });
        start = "";
      }
    });
  });

  let days = [
    ...(daysOpen.includes(1)
      ? [getCurrentWeek().add(1, "day").format("YYYY-MM-DD")]
      : []),
    ...(daysOpen.includes(2)
      ? [getCurrentWeek().add(2, "day").format("YYYY-MM-DD")]
      : []),
    ...(daysOpen.includes(3)
      ? [getCurrentWeek().add(3, "day").format("YYYY-MM-DD")]
      : []),
    ...(daysOpen.includes(4)
      ? [getCurrentWeek().add(4, "day").format("YYYY-MM-DD")]
      : []),
    ...(daysOpen.includes(5)
      ? [getCurrentWeek().add(5, "day").format("YYYY-MM-DD")]
      : []),
    ...(daysOpen.includes(6)
      ? [getCurrentWeek().add(6, "day").format("YYYY-MM-DD")]
      : []),
  ];

  const opens = times.map((element) => {
    return {
      start: parseInt((element.start || "").split(":")[0]),
      end: parseInt((element.end || "").split(":")[0]),
    };
  });

  let divided: Array<number> = [];

  if (slot) {
    for (let index = 0; index < 60 / slot; index++) {
      divided.push(index * slot);
    }
  }

  let data: any = {};

  let daysDates: any = {};

  opens.forEach((open, idx) => {
    for (let i = 0; i < open.end - open.start; i++) {
      if (!data.hasOwnProperty(open.start + i)) {
        data[open.start + i] = [];
      }

      days.forEach((day) => {
        if (!daysDates.hasOwnProperty(day)) {
          daysDates[day] = [];
        }

        let dataColumn: Array<JSX.Element> = [];

        divided.forEach((value, idxValue) => {
          const date = moment(`${day} ${open.start}:${value}:00`);
          const date1 = moment(date)
            .add(60 * i, "minutes")
            .format("YYYY-MM-DD HH:mm:ss");

          const idx = emptyAppointments.findIndex(
            (appointment) =>
              moment(appointment.date).format("YYYY-MM-DD HH:mm:ss") === date1
          );

          let isDisabled = false;

          if (
            moment(date1).isBefore(moment()) ||
            holidays.includes(moment(date1).format("YYYY-MM-DD"))
          ) {
            isDisabled = true;
          }

          let color = isDisabled ? "#777777" : "#C2C2C2";
          if (idx >= 0) {
            const appointment = emptyAppointments[idx];

            if (appointment.status === 0) {
              if (isDisabled) {
                color = "#777777";
              } else {
                color = "#53D426";
              }
            } else {
              color = "#FFBB38";
            }
          }

          if (!isDisabled && idx < 0) {
            daysDates[day].push(date1);
          }

          dataColumn.push(
            <div
              key={idxValue}
              onClick={() => {
                if (!isDisabled) {
                  if (idx >= 0) {
                    if (emptyAppointments[idx].status > 0) {
                      setIsModalAppointment(idx);
                    } else {
                      dispatch(
                        deleteEmptyAppointment(emptyAppointments[idx].id)
                      );
                    }
                  } else {
                    dispatch(insertEmptyAppointment(date1));
                  }
                }
              }}
              className={isDisabled ? "cursor-disabled" : "cursor-pointer"}
              style={{
                backgroundColor: color,
              }}
            >
              <span
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                {value}
              </span>
            </div>
          );
        });

        data[open.start + i].push(
          <td className="no-border" key={idx}>
            <div className="d-flex">{dataColumn}</div>
          </td>
        );
      });
    }
  });

  return (
    <>
      <Col xs={12}>
        <Card
          noHeight
          title={`Gestion des disponibilités pour le dépôt du véhicule (${countAvailable})`}
          rightHeader={
            <div
              className="d-flex"
              style={{
                gap: 10,
                alignItems: "center",
              }}
            >
              <div
                className="d-flex"
                style={{
                  gap: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  icon={"CaretLeft"}
                  size={18}
                  color="var(--secondary-color)"
                  onClick={() => setCurrentWeek(currentWeek - 1)}
                />
                <span
                  style={{
                    color: "var(--secondary-color)",
                    whiteSpace: "nowrap",
                  }}
                >
                  {getCurrentWeek().add(1, "day").format("DD/MM/YYYY")} -{" "}
                  {getCurrentWeek()
                    .endOf("week")
                    .add(1, "day")
                    .format("DD/MM/YYYY")}
                </span>
                <Icon
                  icon={"CaretRight"}
                  size={18}
                  color="var(--secondary-color)"
                  onClick={() => setCurrentWeek(currentWeek + 1)}
                />
              </div>
              <Icon
                icon="Setting"
                size={20}
                onClick={() => setIsModalTableAppointments(true)}
              />
            </div>
          }
        >
          <>
            <div
              style={{
                height: "100%",
                overflow: "auto",
              }}
            >
              <table className="table-garage">
                <thead>
                  <tr>
                    <th></th>
                    {daysOpen.includes(1) ? (
                      <th>
                        <div className="m-5 d-flex gap-5 align-center">
                          <span>Lundi</span>
                          <span>{moment(days[0]).format("DD")}</span>
                          {!holidays.includes(
                            moment(days[0]).format("YYYY-MM-DD")
                          ) ? (
                            <button
                              className="table-button cursor-pointer ml-auto w-auto"
                              onClick={() =>
                                _insertEmptyAppointments(daysDates[days[0]])
                              }
                            >
                              Activer
                            </button>
                          ) : null}
                        </div>
                      </th>
                    ) : null}
                    {daysOpen.includes(2) ? (
                      <th>
                        <div className="m-5 d-flex gap-5 align-center">
                          <span>Mardi</span>
                          <span>{moment(days[1]).format("DD")}</span>
                          {!holidays.includes(
                            moment(days[1]).format("YYYY-MM-DD")
                          ) ? (
                            <button
                              className="table-button cursor-pointer ml-auto w-auto"
                              onClick={() =>
                                _insertEmptyAppointments(daysDates[days[1]])
                              }
                            >
                              Activer
                            </button>
                          ) : null}
                        </div>
                      </th>
                    ) : null}
                    {daysOpen.includes(3) ? (
                      <th>
                        <div className="m-5 d-flex gap-5 align-center">
                          <span>Mercredi</span>
                          <span>{moment(days[2]).format("DD")}</span>
                          {!holidays.includes(
                            moment(days[2]).format("YYYY-MM-DD")
                          ) ? (
                            <button
                              className="table-button cursor-pointer ml-auto w-auto"
                              onClick={() =>
                                _insertEmptyAppointments(daysDates[days[2]])
                              }
                            >
                              Activer
                            </button>
                          ) : null}
                        </div>
                      </th>
                    ) : null}
                    {daysOpen.includes(4) ? (
                      <th>
                        <div className="m-5 d-flex gap-5 align-center">
                          <span>Jeudi</span>
                          <span>{moment(days[3]).format("DD")}</span>

                          {!holidays.includes(
                            moment(days[3]).format("YYYY-MM-DD")
                          ) ? (
                            <button
                              className="table-button cursor-pointer ml-auto w-auto"
                              onClick={() =>
                                _insertEmptyAppointments(daysDates[days[3]])
                              }
                            >
                              Activer
                            </button>
                          ) : null}
                        </div>
                      </th>
                    ) : null}
                    {daysOpen.includes(5) ? (
                      <th>
                        <div className="m-5 d-flex gap-5 align-center">
                          <span>Vendredi</span>
                          <span>{moment(days[4]).format("DD")}</span>
                          {!holidays.includes(
                            moment(days[4]).format("YYYY-MM-DD")
                          ) ? (
                            <button
                              className="table-button cursor-pointer ml-auto w-auto"
                              onClick={() =>
                                _insertEmptyAppointments(daysDates[days[4]])
                              }
                            >
                              Activer
                            </button>
                          ) : null}
                        </div>
                      </th>
                    ) : null}
                    {daysOpen.includes(6) ? (
                      <th>
                        <div className="m-5 d-flex gap-5 align-center">
                          <span>Samedi</span>
                          <span>{moment(days[5]).format("DD")}</span>
                          {!holidays.includes(
                            moment(days[5]).format("YYYY-MM-DD")
                          ) ? (
                            <button
                              className="table-button cursor-pointer ml-auto w-auto"
                              onClick={() =>
                                _insertEmptyAppointments(daysDates[days[5]])
                              }
                            >
                              Activer
                            </button>
                          ) : null}
                        </div>
                      </th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(data).map((key) => (
                    <tr key={key}>
                      <th>{key}</th>
                      {
                        // @ts-ignore
                        data[key].map((subElement, subIdx: number) => (
                          <Fragment key={subIdx}>{subElement}</Fragment>
                        ))
                      }
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex" style={{ gap: 10, margin: 5 }}>
              <div className="d-flex" style={{ alignItems: "center", gap: 10 }}>
                <div
                  style={{
                    backgroundColor: "#53D426",
                    width: 40,
                    height: 14,
                    borderRadius: 8,
                  }}
                ></div>
                <span style={{ fontSize: 14 }}>Libre</span>
              </div>
              <div className="d-flex" style={{ alignItems: "center", gap: 10 }}>
                <div
                  style={{
                    backgroundColor: "#FFBB38",
                    width: 40,
                    height: 14,
                    borderRadius: 8,
                  }}
                ></div>
                <span style={{ fontSize: 14 }}>Réservé</span>
              </div>
              <div className="d-flex" style={{ alignItems: "center", gap: 10 }}>
                <div
                  style={{
                    backgroundColor: "#C2C2C2",
                    width: 40,
                    height: 14,
                    borderRadius: 8,
                  }}
                ></div>
                <span style={{ fontSize: 14 }}>Désactivé</span>
              </div>
              <button
                className="table-button cursor-pointer ml-auto w-auto"
                onClick={() => {
                  if (
                    daysOpen.includes(1) &&
                    !holidays.includes(
                      moment(daysDates[days[0]]).format("YYYY-MM-DD")
                    )
                  ) {
                    _insertEmptyAppointments(daysDates[days[0]]);
                  }
                  if (
                    daysOpen.includes(2) &&
                    !holidays.includes(
                      moment(daysDates[days[1]]).format("YYYY-MM-DD")
                    )
                  ) {
                    _insertEmptyAppointments(daysDates[days[1]]);
                  }
                  if (
                    daysOpen.includes(3) &&
                    !holidays.includes(
                      moment(daysDates[days[2]]).format("YYYY-MM-DD")
                    )
                  ) {
                    _insertEmptyAppointments(daysDates[days[2]]);
                  }
                  if (
                    daysOpen.includes(4) &&
                    !holidays.includes(
                      moment(daysDates[days[3]]).format("YYYY-MM-DD")
                    )
                  ) {
                    _insertEmptyAppointments(daysDates[days[3]]);
                  }
                  if (
                    daysOpen.includes(5) &&
                    !holidays.includes(
                      moment(daysDates[days[4]]).format("YYYY-MM-DD")
                    )
                  ) {
                    _insertEmptyAppointments(daysDates[days[4]]);
                  }
                  if (
                    daysOpen.includes(6) &&
                    !holidays.includes(
                      moment(daysDates[days[5]]).format("YYYY-MM-DD")
                    )
                  ) {
                    _insertEmptyAppointments(daysDates[days[5]]);
                  }
                }}
              >
                Activer toute la semaine
              </button>
            </div>
          </>
        </Card>
      </Col>
      {isModalAppointment >= 0 ? (
        <ModalAppointment
          appointment={emptyAppointments[isModalAppointment]}
          onClose={() => setIsModalAppointment(-1)}
        />
      ) : null}
      {isModalTableAppointments ? (
        <ModalTableAppointments
          onClose={() => setIsModalTableAppointments(false)}
        />
      ) : null}
    </>
  );
}

export default TableAppointments;
