import { getCurrencyValue } from "../../utils/general";

import caretRight from "../../images/caret-right.png";

import { useAppDispatch } from "../../reducers";
import { ClientAction } from "../../actions/clientAction";

type CardHeaderProps = {
  text: string;
  price: number;
  color: string;
};

function CardHeader({ text, price, color }: CardHeaderProps) {
  const dispatch = useAppDispatch();

  return (
    <div
      className="cursor-pointer"
      style={{
        display: "flex",
        backgroundColor: "#fff",
        boxShadow: "2px 4px 12px 0px rgba(80, 41, 41, 0.15)",
        padding: 10,
        borderRadius: 10,
        borderLeft: `${color} solid 14px`,
        flexGrow: 1,
      }}
      onClick={() =>
        dispatch({ type: ClientAction.SET_GARAGE_ID, body: { garageId: 0 } })
      }
    >
      <div>
        <p>{text}</p>
        <p style={{ color: color }}>{getCurrencyValue(price, 0)}</p>
      </div>
      <div
        style={{
          marginLeft: "auto",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <img alt="caret" height={20} src={caretRight} />
      </div>
    </div>
  );
}

export default CardHeader;
