export interface ResultBase64 {
  base64: string;
  type: string;
}

const getBase64 = (file: Blob) => {
  return new Promise<ResultBase64>((resolve, reject) => {
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      const maxSize = 1300;

      if (
        reader.result &&
        typeof reader.result === "string" &&
        (reader.result.includes("image/png") ||
          reader.result.includes("image/jpg") ||
          reader.result.includes("image/jpeg"))
      ) {
        let image = new Image();
        image.src = reader.result;
        image.onload = () => {
          let finalWidth;
          let finalHeight;

          let initialWidth = image.width;
          let initialHeight = image.height;
          if (initialWidth > initialHeight) {
            if (initialWidth > maxSize) {
              finalWidth = maxSize;
              finalHeight = (finalWidth * initialHeight) / initialWidth;
            } else {
              finalWidth = initialWidth;
              finalHeight = initialHeight;
            }
          } else {
            if (initialHeight > maxSize) {
              finalHeight = maxSize;
              finalWidth = (finalHeight * initialWidth) / initialHeight;
            } else {
              finalWidth = initialWidth;
              finalHeight = initialHeight;
            }
          }

          if (finalWidth !== initialWidth) {
            const canvas = document.createElement("canvas");
            let ctx = canvas.getContext("2d");
            canvas.width = finalWidth;
            canvas.height = finalHeight;
            if (ctx) {
              ctx.drawImage(image, 0, 0, finalWidth, finalHeight);
              resolve({
                base64: canvas.toDataURL("image/jpeg", 0.86),
                type: file.type,
              });
            } else {
              resolve({ base64: reader.result as string, type: file.type });
            }
          } else {
            resolve({ base64: reader.result as string, type: file.type });
          }
        };
        image.onerror = () => {
          resolve({ base64: reader.result as string, type: file.type });
        };
      } else {
        resolve({ base64: reader.result as string, type: file.type });
      }
    };

    reader.onerror = () => {
      reject();
    };
  });
};

export { getBase64 };
