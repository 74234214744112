import {
  Appointment,
  TgarageInitialState,
  EmptyAppointment,
} from "../types/garage";

import { GarageAction } from "../actions/garageAction";

export const initialState = {
  garages: [],
  emptyAppointments: [],
  appointments: [],
  tenders: [],
  tender: null,
  pathTender: "",
  tendersHistory: [],
  appointmentsHistory: [],
  aroundPrices: {},
} satisfies TgarageInitialState;

export const garageReducer = (state = initialState, action: any) => {
  let tmpEmptyAppointments = state.emptyAppointments as Array<EmptyAppointment>;

  switch (action.type) {
    case GarageAction.GET_EMPTY_APPOINTMENTS:
      return {
        ...state,
        emptyAppointments: (action.emptyAppointments as EmptyAppointment) || [],
      };
    case GarageAction.INSERT_EMPTY_APPOINTMENTS:
      if (action.success) {
        const item = {
          date: action.body.date as string,
          id: action.id as number,
          status: 0,
        } as EmptyAppointment;

        tmpEmptyAppointments.push(item);
      }

      return {
        ...state,
        emptyAppointments: tmpEmptyAppointments,
      };
    case GarageAction.DELETE_EMPTY_APPOINTMENTS:
      if (action.success) {
        const idx = tmpEmptyAppointments.findIndex(
          (appointment) => appointment.id === action.body.id
        );

        if (idx >= 0) {
          tmpEmptyAppointments.splice(idx, 1);
        }
      }

      return {
        ...state,
        emptyAppointments: tmpEmptyAppointments,
      };
    case GarageAction.GET_APPOINTMENTS:
      return {
        ...state,
        appointments: action.data || [],
      };
    case GarageAction.UPDATE_APPOINTMENTS:
      return {
        ...state,
        appointments: action.success
          ? [...state.appointments].filter(
              (appointment: Appointment) =>
                appointment.id !== action.body.folderId
            )
          : state.appointments,
      };
    case GarageAction.GET_TENDERS:
      return {
        ...state,
        tenders: action.success ? action.data : [],
      };
    case GarageAction.GET_TENDER:
      return {
        ...state,
        tender: action.success ? action.data : null,
      };
    case GarageAction.SET_PATH_TENDER:
      return {
        ...state,
        pathTender: action.body.path,
      };
    case GarageAction.GET_TENDERS_HISTORY:
      return {
        ...state,
        tendersHistory: action.success ? action.data : [],
      };
    case GarageAction.GET_APPOINTMENTS_HISTORY:
      return {
        ...state,
        appointmentsHistory: action.success ? action.data : [],
      };
    case GarageAction.GET_PRICES_AROUND:
      return {
        ...state,
        aroundPrices: action.success ? action.aroundPrices : [],
      };
    default:
      return state;
  }
};
