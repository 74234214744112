import { useSelector } from "react-redux";
import { useEffect, useCallback } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { TbaseReducer } from "../types/reducerType";

export default function GarageAuth() {
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector(
    (state: TbaseReducer) => state.authReducer
  );

  const redirect = useCallback(() => {
    navigate("/garage/");
  }, [navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      redirect();
    }
  }, [isAuthenticated, redirect]);

  return (
    <>
      <Outlet />
    </>
  );
}
