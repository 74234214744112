import moment from "moment";

import icon from "../../images/icon-bleu.png";

export default function Timer() {
  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const days = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ];

  return (
    <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
      <div style={{ textAlign: "right" }}>
        <h2
          style={{
            margin: 0,
            fontSize: "42px",
            fontWeight: "600",
            lineHeight: "130%",
            color: "var(--primary-bg-color)",
          }}
        >
          {moment().format("HH")}h{moment().format("mm")}
        </h2>
        <h3
          style={{
            margin: 0,
            fontSize: "24px",
            fontWeight: "600",
            lineHeight: "130%",
            color: "rgba(11, 44, 70, 0.4)",
          }}
        >
          {days[moment().day() - 1]} {moment().format("DD")}{" "}
          {months[parseInt(moment().format("M")) - 1]}
        </h3>
      </div>
      <img src={icon} alt="logo" height={75} />
    </div>
  );
}
